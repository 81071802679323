import moment from 'moment';
import * as api from '../api';
import { process } from '@progress/kendo-data-query';

import {
  showLoadingOverlay,
  hiddenLoadingOverlay,
} from './app';
import { SET_DATASOURCE, SET_REPORT_DATASOURCE,
  UPDATE_REPORT_DATE_PERIOD,
  SET_CROSS_FILTER,
  REMOVE_CROSS_FILTER,
  UPDATE_REPORT_TABLES,
  SET_REPORT_DATASOURCE_RAW } from './ActionTypes';
import { LOCAL_STORE_APIKEY } from '../../Share/constants';

export function onGetDataFromScource(sourceId, from, to, refresh = false, reportsHavingSameDataSource = []) {
  return (dispatch, getState) => {
    try {
      const state = getState();
      const fromDate = moment(from);
      const toDate = moment(to);

      const scourcePrviousState = state.dataSources.sources[sourceId];
      const { urlPart } = scourcePrviousState;
      const agreements = state.user.agreements.map(x => x.id).join(',');
      const isDataRangeDifferent = !((
        fromDate.diff(scourcePrviousState.range.from, 'day') === 0
        && toDate.diff(scourcePrviousState.range.to, 'day') === 0));

      if ((scourcePrviousState.data.length === 0 || refresh) && isDataRangeDifferent && agreements !== '') {
        const apiKey = localStorage.getItem(LOCAL_STORE_APIKEY);
        dispatch(showLoadingOverlay());
        reportsHavingSameDataSource.forEach((reportItem) => {
          dispatch({
            type: SET_REPORT_DATASOURCE,
            reportId: reportItem.id,
            processData: [],
          });
        });
        api.GetDataFromScource(urlPart, fromDate.format('MM-DD-YYYY'), toDate.format('MM-DD-YYYY'), agreements, apiKey)
          .then((response) => {
            let { data } = response;
            if (data.length > 0 && Object.keys(data[0]).includes('month')) {
              data = data.map(x => ({ ...x, month: moment(x.month).toDate(), monthYear: moment(x.month).format('MMMM yyyy') }));
            }
            dispatch(hiddenLoadingOverlay());
            dispatch({
              type: SET_DATASOURCE,
              data,
              range: { from: fromDate, to: toDate },
              sourceId,
            });
            dispatch(processReportsData(reportsHavingSameDataSource, data));
          }, (error) => {
            dispatch(hiddenLoadingOverlay());
          });
      }
    } catch (error) {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function onGetDataPerTableFromScource(sourceId, from, to, refresh = false, reportsHavingSameDataSource = []) {
  return (dispatch, getState) => {
    try {
      const state = getState();
      const fromDate = moment(from);
      const toDate = moment(to);

      const scourcePrviousState = state.dataSources.sources[sourceId];
      const { urlPart } = scourcePrviousState;
      const agreements = state.user.agreements.map(x => x.id).join(',');
      const isDataRangeDifferent = !((
        fromDate.diff(scourcePrviousState.range.from, 'day') === 0
        && toDate.diff(scourcePrviousState.range.to, 'day') === 0));

      if ((scourcePrviousState?.data?.length === 0 || refresh) && isDataRangeDifferent && agreements !== '') {
        dispatch(showLoadingOverlay());
        const apiGroupsToBeRequest = [];
        const tableWithCommonGroupsToBeRequest = [];
        const apiKey = localStorage.getItem(LOCAL_STORE_APIKEY);
        reportsHavingSameDataSource.forEach((reportItem) => {
          const reportTables = reportItem.tables;
          const processData = reportTables.map(x => []);
          dispatch({
            type: SET_REPORT_DATASOURCE,
            reportId: reportItem.id,
            processData,
            dataReadyFlags: reportTables.map(x => false),
          });
          reportTables.forEach((tableItem) => {
            tableWithCommonGroupsToBeRequest.push({
              groupColumns: tableItem.groupColumns,
              table: tableItem,
              reportTables,
              report: reportItem,
            });
            if (apiGroupsToBeRequest.filter(x => x.groupColumns === tableItem.groupColumns).length === 0) {
              apiGroupsToBeRequest.push({
                groupColumns: tableItem.groupColumns,
                table: tableItem,
                reportTables,
                report: reportItem,
              });
            }
          });
        });
        apiGroupsToBeRequest.sort((a, b) => a.groupColumns.split(',').length - b.groupColumns.split(',').length).forEach((apiItem) => {
          api.GetDataFromScource(urlPart, fromDate.format('MM-DD-YYYY'), toDate.format('MM-DD-YYYY'), agreements, apiKey, apiItem.groupColumns)
            .then((response) => {
              let { data } = response;
              if (data.length > 0 && Object.keys(data[0]).includes('month')) {
                data = data.map(x => ({ ...x, month: moment(x.month).toDate(), monthYear: moment(x.month).format('MMMM yyyy') }));
              }
              dispatch(hiddenLoadingOverlay());
              dispatch(processDataPerGroupColumnSet(apiItem.groupColumns, sourceId,tableWithCommonGroupsToBeRequest.filter(x => x.groupColumns == apiItem.groupColumns), data));
            }, (error) => {
              dispatch(hiddenLoadingOverlay());
            });
        });
        dispatch({
          type: SET_DATASOURCE,
          range: { from: fromDate, to: toDate },
          sourceId,
        });
      }
    } catch (error) {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function ChangeReportDatePeriod(datePeriod, reportId) {
  return (dispatch, getState) => {
    const { start, end } = datePeriod;
    const state = getState();
    const { range, dataSources } = state.reportDetails.reports[reportId];
    const reportsHavingSameDataSource = Object.values(state.reportDetails.reports).filter(x => x.dataSources.join(',') == dataSources.join(',')) || [];
    const newRange = {
      from: start,
      to: end,
    };

    dataSources.forEach((source) => {
      const reportWithTableLevelDataSource = reportsHavingSameDataSource.filter(x => x.isTableLeveldataSource);
      if (reportWithTableLevelDataSource.length > 0) {
        dispatch(onGetDataPerTableFromScource(source, start, end, true, reportWithTableLevelDataSource));
      } else {
        dispatch(onGetDataFromScource(source, start, end, true, reportsHavingSameDataSource.filter(x => !x.isTableLeveldataSource)));
      }
    });
    dispatch({
      type: UPDATE_REPORT_DATE_PERIOD,
      reportIds: reportsHavingSameDataSource.map(x => x.id),
      range: newRange,
    });
  };
}

export function setDatasourceCrossFilter(reportId, filter) {
  return (dispatch, getState) => {
    const state = getState();
    const { dataSources } = state.reportDetails.reports[reportId];
    dataSources.forEach((sourceId) => {
      dispatch({
        type: SET_CROSS_FILTER,
        sourceId,
        filter,
      });
    });
  };
}

export function removeDatasourceCrossFilter() {
  return (dispatch, getState) => {
    const state = getState();
    const { sourceIds } = state.app.dashboardFilter;
    if (sourceIds) {
      sourceIds.forEach((sourceId) => {
        dispatch({
          type: REMOVE_CROSS_FILTER,
          sourceId,
        });
      });
    }
  };
}

const processGroupByData = (data, dataState) => {
  const procesData = process(data, dataState);
  const returnData = [];
  procesData.data.forEach((element) => {
    const returnElement = {};
    returnElement[dataState.group[0].field] = element.value;
    extractKeyValue(returnElement, element);
    const aggregatesKeys = [...Object.keys(element.aggregates)];
    aggregatesKeys.forEach((aggrItem) => {
      returnElement[aggrItem] = element.aggregates[aggrItem].sum;
    });
    Object.keys(returnElement).forEach(key => returnElement[key] === undefined && delete returnElement[key]);
    returnData.push(returnElement);
  });
  return returnData;
};

const extractKeyValue = (returnObj, element) => {
  if (element.items && element.items.length > 0) {
    element.items.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      returnObj[item.field] = item.value;
      extractKeyValue(returnObj, item);
    });
  }
  return returnObj;
};

const processGroupByProperty = (aggregateOn, aggregateElement, groupbyInterpreter = [], tableId, tables, reportId, dispatch) => {
  const processData = [];
  const columns = [];
  groupbyInterpreter.forEach((element) => {
    const processElement = {};
    processElement[aggregateOn] = element.value;
    element.items.forEach((item) => {
      processElement[item.value] = item.aggregates[aggregateElement] ?
        item.aggregates[aggregateElement].sum : '0.00';

      if (columns.indexOf(item.value) < 0) {
        columns.push(item.value);
      }
    });
    processData.push(processElement);
  });
  processTableColumns(columns.sort(), tableId, [], tables, reportId, dispatch);
  return processData;
};

const processDistributionPerProperty = (aggregateOn, aggregateElements = [], groupbyInterpreter = [], tableId, tables, reportId, dispatch) => {
  const processData = [];
  const columns = [];
  const distributeColumns = [];
  groupbyInterpreter.forEach((element) => {
    const processElement = {};
    processElement[aggregateOn] = element.value;
    element.items.forEach((item) => {
      aggregateElements.forEach((aggregateElement) => {
        const aggregateKey = `${item.value}-${aggregateElement}`;
        processElement[aggregateKey] = item.aggregates[aggregateElement] ?
          item.aggregates[aggregateElement].sum : '0.00';
        if (columns.indexOf(aggregateKey) < 0) {
          columns.push(aggregateKey);
        }
        if (distributeColumns.indexOf(item.value) < 0) {
          distributeColumns.push(item.value);
        }
      });
    });
    processData.push(processElement);
  });
  processTableColumns(columns, tableId, distributeColumns.sort(), tables, reportId, dispatch);
  return processData;
};


const processTableColumns = (columns, tableId, distributionColumns = [], tables, reportId, dispatch) => {
  const returnColumnStruct = [tables[tableId].columnStruct[0]];
  if (distributionColumns.length > 0) {
    distributionColumns.forEach((dColumn) => {
      const matchChangedObj = tables[tableId].columnStruct.filter(column => column.title === dColumn);
      returnColumnStruct.push({
        showOnGrid: matchChangedObj[0] ? matchChangedObj[0].showOnGrid : true,
        title: dColumn,
        subColumn: columns.filter(x => x.includes(dColumn)).map((column) => {
          const matchChangedSubObj = matchChangedObj[0] ? matchChangedObj[0].subColumn
            .filter(x => x.field === column) : [];
          return {
            field: column,
            showOnGrid: matchChangedSubObj[0] ? matchChangedSubObj[0].showOnGrid : true,
            format: '{0:n0}',
            className: 'text-float-right',
            title: column,
            width: '240px',
            sortable: true,
          };
        }),
      });
    });
  } else {
    columns.forEach((x) => {
      const matchChangedObj = tables[tableId].columnStruct.filter(column => column.field === x);
      returnColumnStruct.push({
        field: x,
        showOnGrid: matchChangedObj[0] ? matchChangedObj[0].showOnGrid : true,
        title: x,
        width: '240px',
        sortable: true,
      });
    });
  }
  tables[tableId] = { ...tables[tableId], columnStruct: returnColumnStruct };

  dispatch({
    type: UPDATE_REPORT_TABLES,
    reportId,
    tables,
  });
};

export function processSingleTableData(table, data, tables, reportId, dispatch) {
  switch (table.type) {
    case 'property group': {
      return processGroupByProperty(
        table.columGroup.aggregateOn,
        table.columGroup.aggregateElements[0], process(data, table.groupTable).data, table.id, tables, reportId, dispatch,
      );
    }
    case 'distribute property group': {
      return processDistributionPerProperty(
        table.columGroup.aggregateOn, table.columGroup.aggregateElements,
        process(data, table.groupTable).data, table.id, tables, reportId, dispatch,
      );
    }
    default: {
      return processGroupByData(data, table.groupTable);
    }
  }
}

async function processTablesData(data, tables, reportId, dispatch) {
  return tables.map(x => processSingleTableData(x, data, tables, reportId, dispatch));
}

function processReportsData(reportsHavingSameDataSource, data) {
  return (dispatch, getState) => {
    const state = getState();

    reportsHavingSameDataSource.forEach(async (x) => {
      const processData = await processTablesData(
        data.filter(y => (
          state.app.selectedAgreement.id === 0 ?
            true :
            y.agreementId === state.app.selectedAgreement.id))
        , x?.tables || [], x.id, dispatch,
      );
      dispatch({
        type: SET_REPORT_DATASOURCE,
        reportId: x.id,
        processData,
        dataReadyFlags: x?.tables.map(y => true),
      });
    });
  };
}


function processReportDataByTable(processingTable, tables, reportId, sourceId, data) {
  return (dispatch, getSate) => {
    const processedTableData = processSingleTableData(
      processingTable,
      data.filter(y => (
        getSate().app.selectedAgreement.id === 0 ?
          true :
          y.agreementId === getSate().app.selectedAgreement.id))
      , tables, reportId, dispatch,
    );
    const state = getSate();
    const reportCurrentData = state.reportDataSources[reportId].crossFilterData;
    const reportcurrentdataReadyFlags = state.reportDataSources[reportId].dataReadyFlags;
    reportCurrentData[processingTable.id] = processedTableData;
    reportcurrentdataReadyFlags[processingTable.id] = true;
    dispatch({
      type: SET_REPORT_DATASOURCE,
      reportId,
      dataReadyFlags: reportcurrentdataReadyFlags,
      processData: reportCurrentData,
    });
  };
}

function processDataPerGroupColumnSet(groupColumnsKey, sourceId, tablesToBeProcessed, data) {
  return (dispatch) => {
    dispatch({
      type: SET_REPORT_DATASOURCE_RAW,
      sourceId,
      groupColumnsKey,
      rawData: data,
    });
    tablesToBeProcessed.forEach((item) => {
      dispatch(processReportDataByTable(item.table, item.reportTables, item.report.id, sourceId, data));
    });
  };
}

export function updateTablesOfReports(reportId, tables) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_REPORT_TABLES,
      reportId,
      tables,
    });
  };
}
