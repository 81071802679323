import React from 'react';
import { NavLink } from 'react-router-dom';
import { Translate, } from '../../../Share/components';


const propTypes = {
};

const defaultProps = {
};


const PrivacyPolicy = (props) => {
  const { history } = props;
  const isPublicRoute = !!(history && history.location && history.location.pathname === '/privacy-policy');
  return (
    <div className="tab-panel-content">
      <p />
      <p />
      <h4 className="tab-panel-content__heading1"><b>Personuppgiftspolicy</b></h4>
      <p><b /></p>
      <p className="tab-panel-content__paragraph">Denna personuppgiftspolicy (härefter ”Personuppgiftspolicyn”) beskriver hur Transvoice behandlar Dina personuppgifter när Du använder Tolka!, tolk.transvoice.se samt kund.transvoice.se samt andra appar och webbtjänster (gemensamt benämnda ”Transvoicetjänsterna”) som från tid till annan tillhandahålls av Transvoice Sweden AB, org.nr: 556653-6370, samt av bolagets dotterbolag, inkluderande bl.a. Transvoice AB, org. nr: 556482-8654 (gemensamt benämnda ”Transvoice”). Personuppgiftspolicyn beskriver även den behandling av Dina personuppgifter som Transvoice utför för att administrera bokningar i Transvoices interna orderhanterings- och förmedlingssystem.</p>

      <p />
      <p className="tab-panel-content__heading2"><b>1. Transvoice ansvar för behandlingen</b></p>
      <p className="tab-panel-content__paragraph">Transvoice är personuppgiftsansvarig för behandlingen av Dina personuppgifter och är ansvarig för att all personuppgiftsbehandling i Transvoicetjänsterna och i Transvoices interna orderhanterings- och förmedlingssystem utförs i enlighet med tillämpliga lagar och regler. Transvoice är mån om skyddet av Din personliga integritet och vill att Du ska känna till hur Dina personuppgifter behandlas och för vilka ändamål behandlingen utförs. Om Du har några frågor eller synpunkter rörande Transvoices behandling av Dina personuppgifter äger du rätt att närsomhelst kontakta Transvoice på <a href="mailto:kvalitet@transvoice.se">kvalitet@transvoice.se</a>.</p>

      <p />
      <p className="tab-panel-content__heading2"><b>2. Ändamålet med behandlingen</b></p>
      <p className="tab-panel-content__paragraph">Transvoice behöver behandla Dina personuppgifter för att kunna tillhandahålla Dig Transvoicetjänsterna, vilka tillhandahålls i enlighet med Användarvillkoren som gäller för Din användning av Transvoicetjänsterna, [länk till Användarvillkoren]. Den lagliga grunden för behandlingen av Dina personuppgifter är att Transvoice behöver behandla Dina personuppgifter för att kunna tillhandahålla Dig Transvoicetjänsterna och samtliga funktioner i Transvoicetjänsterna i enlighet med Användarvillkoren som utgör ett avtal mellan Dig och Transvoice.</p>
      <p className="tab-panel-content__paragraph">När Dina personuppgifter behandlas i syfte att Transvoice ska kunna tillhandahålla samtliga funktioner i Transvoicetjänsterna till någon annan användare utgör den lagliga grunden för behandlingen att Transvoice har ett berättigat intresse att behandla Dina personuppgifter för ändamålet att tillhandahålla Transvoicetjänsterna.</p>
      <p className="tab-panel-content__paragraph">Transvoice hanterar och lagrar Dina personuppgifter i ett internt orderhanterings- och förmedlingssystem. Den nämnda behandlingen i orderhanterings- och förmedlingssystemet genomför Transvoice för att administrera och hantera kunders och anlitade tolkars bokningar och uppdrag. Den lagliga grunden för personuppgiftsbehandlingen är att Transvoice har ett berättigat intresse av att behandla personuppgifterna för att kunna tillhandahålla tolkförmedlartjänster. I det fall personuppgifterna avser en tolk som ingått avtal med Transvoice i egenskap av privatperson eller enskild näringsidkare utförs behandlingen med stöd av att behandlingen är nödvändig för att fullgöra Transvoice allmänna villkor och riktlinjer för tolkuppdrag, vilka utgör ett bindande avtal mellan tolken och Transvoice.</p>
      <p className="tab-panel-content__paragraph">Transvoice behandlar även Dina personuppgifter i syfta att analysera Din användning av Transvoicetjänsterna för att kunna utveckla och förbättra Transvoicetjänsterna. Den lagliga grunden för den nämnda behandlingen av Dina personuppgifter är att Transvoice har ett berättigat intresse att utveckla och förbättra Transvoicetjänsterna.</p>
      <p className="tab-panel-content__paragraph">Transvoice kan även komma att behandla Dina personuppgifter för att efterleva tillämpliga lagar, regler och myndighetsbeslut. Transvoice kan exempelvis åläggas att lämna ut Dina personuppgifter till behöriga svenska myndigheter. När Transvoice behandlar Dina personuppgifter för det nämnda ändamålet utförs behandlingen med stöd av den lagliga grunden att Transvoice behöver genomföra en rättslig förpliktelse som åvilar Transvoice.</p>


      <p />
      <p className="tab-panel-content__heading2"><b>3. Personuppgifter som behandlas</b></p>
      <p className="tab-panel-content__paragraph">Transvoice samlar in följande personuppgifter:</p>
      <ul>
        <li>Förnamn och efternamn</li>
        <li>Adress och andra kontaktuppgifter</li>
        <li>Användarnamn och lösenord</li>
        <li>Personnummer</li>
        <li>Kön</li>
        <li>Schemauppgifter</li>
        <li>Språkkunskaper</li>
        <li>Utbildning/auktorisation</li>
        <li>Genomförda uppdrag, plats, typ, krav, upplevd kvalitet (kund och tolk)</li>
        <li>Användningshistorik (ex. logg över vilka användare som varit inloggade)</li>
      </ul>

      <p />
      <p className="tab-panel-content__heading2"><b>4. Insamling av personuppgifter</b></p>
      <p className="tab-panel-content__paragraph">Transvoice samlar in personuppgifter från Dig i samband med att Du skapar ett konto för att kunna använda Transvoicetjänsterna.</p>
      <p className="tab-panel-content__paragraph">Det förekommer även att andra användare tillhandahåller personuppgifter som rör Dig genom att personuppgifter som rör Dig laddas upp av någon annan användare i Transvoicetjänsterna.</p>

      <p />
      <p className="tab-panel-content__heading2"><b>5. Lagringstid</b></p>
      <p className="tab-panel-content__paragraph">Transvoice sparar endast Dina personuppgifter under den tid det är nödvändigt för att uppnå de ändamål för vilka personuppgifterna behandlas eller under den tid som krävs enligt gällande lagstiftning.</p>
      <p className="tab-panel-content__paragraph">Personuppgifter som rör Dig och Ditt användarkonto behandlas under den tid som Du har ett aktivt konto för användning av Transvoicetjänsterna samt för en tid om 18 månader därefter. I det fall Du inte har återaktiverat Ditt konto 18 månader efter att Du har tagit bort det raderar Transvoice personuppgifter som rör Dig och Ditt användarkonto, såvida inte personuppgifterna behöver behandlas under en längre tidsperiod enligt gällande lagstiftning.</p>
      <p className="tab-panel-content__paragraph">Tolka!, tolk.transvoice.se samt kund.transvoice.se utgör gränssnitt mot Transvoices bakomliggande orderhanterings- och förmedlingssystem och dina personuppgifter lagras i orderhanterings- och förmedlingssystemet på Transvoices servrar.</p>

      <p />
      <p className="tab-panel-content__heading2"><b>6. Mottagare av Dina personuppgifter</b></p>
      <p className="tab-panel-content__paragraph">Transvoice vidtar åtgärder för att säkerställa att Dina personuppgifter omgärdas av en hög nivå av skydd vid behandlingen.</p>
      <p className="tab-panel-content__paragraph">Transvoice ger endast tillgång till Dina personuppgifter till personer som behöver sådan tillgång för att Transvoice ska kunna tillhandahålla Transvoicetjänsterna och Transvoices tolkförmedlartjänster.</p>
      <p className="tab-panel-content__paragraph">Transvoice kan använda underleverantörer för att tillhandahålla Transvoicetjänsterna och Transvoices tolkförmedlartjänster. När en underleverantör behandlar Dina personuppgifter på uppdrag av Transvoice utgör denne ett personuppgiftsbiträde. Personuppgiftsbiträden som utför tjänster för Transvoices räkning får endast behandla Dina personuppgifter i enlighet med Transvoices instruktioner och i enlighet med bindande avtal om personuppgiftsbehandling och sekretess.</p>
      <p className="tab-panel-content__paragraph">Transvoice kan även komma att ge tillgång till Dina personuppgifter till myndigheter i det fall Transvoice är skyldig att ge sådan tillgång enligt tillämpliga lagar, föreskrifter eller myndighetsbeslut.</p>

      <p />
      <p className="tab-panel-content__heading2"><b>7. Tredjelandsöverföringar</b></p>
      <p className="tab-panel-content__paragraph">Transvoice behandlar Dina personuppgifter på servrar som är belägna inom EU/EES. I det fall Transvoice av något skäl skulle behöva överföra Dina personuppgifter till ett land utanför EU/EES kommer Transvoice informera dig om detta i förhand. Transvoice åtar sig att säkerställa att det finns en laglig grund för överföringar som genomförs till länder utanför EU/EES samt att tillse att Dina personuppgifter omgärdas av ett likvärdigt skydd efter överföringen.</p>

      <p />
      <p className="tab-panel-content__heading2"><b>8. Dina rättigheter</b></p>
      <p className="tab-panel-content__paragraph">Enligt Europaparlamentets och rådets förordning (EU) 2016/679 av den 27 april 2016 om skydd för fysiska personer med avseende på behandling av personuppgifter och om det fria flödet av sådana uppgifter och om upphävande av direktiv 95/46/EG (”GDPR”) har Du ett antal rättigheter som Du äger rätt att göra gällande med anledning av Transvoices behandling av Dina personuppgifter.</p>
      <p className="tab-panel-content__paragraph">Du har rätt att kostnadsfritt vända dig till Transvoice och begära information om behandlingen av personuppgifter som rör Dig. Du har vidare rätt att ta del av Dina personuppgifter i ett maskinläsbart format. Under vissa omständigheter har Du även rätt att begära att Transvoice överför Dina personuppgifter i ett allmänt använt maskinläsbart format till en annan personuppgiftsansvarig.</p>
      <p className="tab-panel-content__paragraph">Du har rätt att invända mot personuppgiftsbehandlingen samt att begära att Transvoice rättar, raderar eller begränsar behandlingen av Dina personuppgifter. Transvoice kommer på Din begäran rätta eller radera personuppgifter som är felaktiga eller begränsa behandlingen av sådana uppgifter. Transvoice kan även radera personuppgifter eller begränsa behandlingen om Du av andra skäl har rätt till det enligt GDPR eller annan tillämplig personuppgiftslagstiftning. Om Du är missnöjd med Transvoices behandling av Dina personuppgifter kan Du lämna in ett klagomål till Datainspektionen.</p>
      <p className="tab-panel-content__paragraph">Du har även möjlighet att ställa frågor rörande personuppgiftsbehandlingen till Transvoice, <a href="mailto:kvalitet@transvoice.se">kvalitet@transvoice.se</a>.</p>

      <p />
      <p className="tab-panel-content__heading2"><b>9. Säkerhetsåtgärder.</b></p>
      <p className="tab-panel-content__paragraph">Transvoice vidtar flertalet åtgärder för att upprätthålla en hög nivå av skydd vid behandlingen av Dina personuppgifter. Transvoice har implementerat tekniska och organisatoriska åtgärder för att hindra obehörig åtkomst, användning och ändring av Dina personuppgifter samt för att säkerställa behörig tillgång till Dina personuppgifter.</p>

      <p />
      <p className="tab-panel-content__heading2"><b>10. Ändringar av Personuppgiftspolicyn</b></p>
      <p className="tab-panel-content__paragraph">Transvoice kan komma att ändra och uppdatera innehållet i Personuppgiftspolicyn. Du blir bunden av uppdaterade versioner av denna Personuppgiftspolicy genom att fortsätta använda Transvoicetjänsterna. Längst ned i denna Personuppgiftspolicy framgår det när Personuppgiftspolicyn senast uppdaterades.</p>
      <p className="tab-panel-content__paragraph">Innan Transvoice genomför väsentliga ändringar av Personuppgiftspolicyn kommer Transvoice att informera Dig. Om Du fortsätter att använda Transvoicetjänsterna, efter att ha erhållit information om en väsentlig ändring av Personuppgiftspolicyn, blir Du bunden av den uppdaterade versionen av Personuppgiftspolicyn.</p>

      <p />
      <br />
      <br />
      <br />
      <p>Transvoice personuppgiftspolicy senast uppdaterad 2019-11-08.</p>
      {isPublicRoute && <NavLink to="/login"><Translate content="login.title" /></NavLink>}
    </div>
  );
}

PrivacyPolicy.propTypes = propTypes;
PrivacyPolicy.defaultProps = defaultProps;

export default PrivacyPolicy;
