import React from 'react';
import PropTypes from 'prop-types';
import { ValidatorComponent } from 'react-form-validator-core';
import classNames from 'classnames';

const propTypes = {
  errorMessages: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  validators: PropTypes.arrayOf(PropTypes.string),
  validatorListener: PropTypes.func,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  rest: PropTypes.shape({}),
};

const defaultProps = {
  errorMessages: [],
  validators: ['matchRegexp:(.*)'],
  validatorListener: () => {},
  value: '',
  options: [],
  className: '',
  rest: {},
};

class DropdownList extends ValidatorComponent {
    state = {};
    render() {
      const { errorMessages, validators, validatorListener, value,
        options, className, ...rest } = this.props;
      const { isValid } = this.state;
      const controlClassName = classNames({
        'tv-form__control': true,
        'tv-form__control--error': !isValid,
        [className]: !!className,
      });
      return (
        <React.Fragment>
          <select
            className={controlClassName}
            {...rest}
            ref={(r) => { this.input = r; }}
            value={value}
          >
            {options ? options.map((item, index) => (
              <option key={index} value={item.value}>
                {item.name}
              </option>
            )) : <option key="" value="" > - </option>}
          </select>
          <span className="tv-form__error">
            {(typeof isValid === 'boolean' && !isValid) ?
              this.getErrorMessage() : ''}
          </span>
        </React.Fragment>
      );
    }
}

DropdownList.propTypes = propTypes;
DropdownList.defaultProps = defaultProps;

export default DropdownList;
