import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  onSelectedAggremenChanged,
} from '../../actions';
import { Translate } from '../../../Share/components';


const propTypes = {
  reportId: PropTypes.number,
  dataSources: PropTypes.arrayOf(PropTypes.number),
  style: PropTypes.shape({}),
  agreements: PropTypes.arrayOf(PropTypes.shape({})),
  selectedAgreement: PropTypes.shape({}),
  onAgreementChange: PropTypes.func,
};
const defaultProps = {
  style: {},
  reportId:0,
  dataSources:[],
  agreements: [],
  selectedAgreement: {},
  onAgreementChange: () => {},
};

const AgreementFilter = ({
  onAgreementChange, agreements, selectedAgreement, style, reportId,dataSources,
}) => (
  <div className="k-textbox-container" style={{ fontFamily: '"Campton", sans-serif', width: '100%' }}>
    <label htmlFor="agreementSelector" className="k-label">{Translate({content:'report.agreement'})}</label>
    <select
      id="agreementSelector"
      className="k-widget"
      value={selectedAgreement.id}
      style={{
        ...style, padding: '8px 20px 5px', borderWidth: '0px 0px 1px 0px', fontSize: '16px',
      }}
      onChange={event => onAgreementChange(agreements.filter(x => x.id == event.target.value)[0], reportId, dataSources)}
    >
      {agreements.map(x => <option value={x.id}  key={x.id}>{x.name}</option>)}
    </select>
  </div>
);

AgreementFilter.propTypes = propTypes;
AgreementFilter.defaultProps = defaultProps;

const mapStateToProps = (state, props) => ({
  agreements: state.user.agreements,
  selectedAgreement: state.app.selectedAgreement,
});

const mapDispatchToProps = dispatch => ({
  onAgreementChange: (agreement, reportId, dataSources) => {
    dispatch(onSelectedAggremenChanged(agreement, reportId, dataSources));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(AgreementFilter);
