import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-form-validator-core';
import { Translate, TransvoiceButton, TextField } from '../../../Share/components';
import { login, onGetUserData } from '../../actions';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: '',
      password: '',
      submitDisabled: true,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { customerName, password } = this.state;
    this.props.onLogin(customerName, password);
    
  }

  handleValidatorListener = () => {
    this.form.isFormValid().then((isValid) => {
      this.setState({ submitDisabled: !isValid });
    });
  }

  render() {
    return (
      <div className="tv-login__wrapper tv-display-flex">
        <div className="tv-login__title"><Translate content="login.title" /></div>
        <div className="tv-login__description">
          <Translate content="login.signInToContinueClient" />
        </div>
        <div className="tv-login__form tv-login__form-wrapper">
          <ValidatorForm
            ref={node => (this.form = node)}
            onSubmit={this.handleSubmit}
            onError={this.handleValidatorListener}
          >
            <div className="tv-login__form-email-lablel tv-login__form-label">
              <Translate content="general.userName" />
            </div>
            <TextField
              validatorListener={this.handleValidatorListener}
              onChange={this.handleChange}
              className="tx-input-text tv-login__form-email-input"
              name="customerName"
              maxLength={50}
              value={this.state.customerName}
              placeholder={Translate({ content: 'login.enterYourUserName' })}
              validators={['required']}
              errorMessages={[Translate({ content: 'login.userNameIsRequire' }), Translate({ content: 'login.userNameIsNotValid' })]}
            />

            <div className="tv-login__form-interpreter-id-label tv-login__form-label">
              <Translate content="general.password" />
            </div>
            <TextField
              type="password"
              validatorListener={this.handleValidatorListener}
              onChange={this.handleChange}
              className="tx-input-text tv-login__form-interpreterId-input"
              name="password"
              value={this.state.password}
              placeholder={Translate({ content: 'login.enterYourPassword' })}
              validators={['minStringLength:8']}
              errorMessages={[Translate({ content: 'login.minLengthMessage' })]}
            />

            <TransvoiceButton
              type="primary"
              buttonType="submit"
              text={Translate({ content: 'login.login' })}
              className="tv-login__form-submit"
              onClick={this.handleSubmit}
              disabled={this.state.submitDisabled}
            />
          </ValidatorForm>
        </div>
        <div className="tv-login-page__right-footer-wrapper">
          <div className="tv-login-page__right-footer-text">
            {/* <NavLink to="/terms-and-conditions"><Translate content="settings.termAndConditions" /></NavLink> <span>|</span> <NavLink to="privacy-policy"><Translate content="settings.termAndPolicy" /></NavLink> */}
          </div>
        </div>
      </div>
    );
  }
}
Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLogin: (customerName, password) => {
    dispatch(login(customerName, password));
  },
});

export default connect(null, mapDispatchToProps)(Login);
