import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
const propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.string,
  name: PropTypes.string,
  maxLength: PropTypes.string,
  rest: PropTypes.shape({}),
};

const defaultProps = {
  className: '',
  placeholder: '',
  value: '',
  name: '',
  maxLength: '',
  rows: 4,
  rest: {}
};

const TextAreaCountWord = ({ value, className, rows, placeholder, maxLength, ...rest }) => (
  <div className={`form_textarea ${className}`}>
    <textarea className={`form_textarea__form-control`} value={value} {...rest}
    rows={rows} placeholder={placeholder} maxLength={maxLength}></textarea>
    <span className="form_textarea__description">{value.length}/{maxLength}</span>
  </div>
);

TextAreaCountWord.propTypes = propTypes;
TextAreaCountWord.defaultProps = defaultProps;

export default TextAreaCountWord;
