import moment from 'moment';
import React from 'react';
import * as actionTypes from '../actions/ActionTypes';
import { Translate } from '../../Share/components';

const INITIAL_STATE = {
  reports: {
    1: {
      id: 1,
      title: 'report.summery',
      range: {
        from: moment().subtract(1, 'month').startOf('month').toDate(),
        to: moment().startOf('month').toDate(),
      },
      excelFileName: `${Translate({ content: 'report.summery' })}-report.xlsx`,
      dataSources: [1],
      dashboard: {
        categoryField: 'interpretationType',
        field: 'noOfAssignments',
        title: Translate({ content: 'report.distributionPerInterpretation' }),
        tableSourceId: 0,
      },
      isTableLeveldataSource: true,
      tables: [
        {
          id: 0,
          title: Translate({ content: 'report.monthlyDistribution' }),
          excelSheetName: `${Translate({ content: 'report.monthlyDistribution' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'monthYear',
              title: Translate({ content: 'report.month' }),
              showOnGrid: true,
              locked: true,
              width: '300px',
            
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'invoicedAmount',
              format: '{0:n0}',
              sortable: true,
              showOnGrid: true,
              title: Translate({ content: 'report.invoicedAmount' }),
            },
          ],
          groupColumns: 'Month',
          groupTable: {
            take: 10,
            group: [
              {
                field: 'monthYear',
                aggregates: [
                  {
                    aggregate: 'sum',
                    field: 'noOfAssignments',
                  },
                  {
                    aggregate: 'sum',
                    field: 'invoicedAmount',
                  },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'monthYear',
            field: 'noOfAssignments',
            title: Translate({ content: 'report.monthlyDistribution' }),
          },
        },
        {
          id: 1,
          title: Translate({ content: 'report.monthlyInterpretationTypeDistribution' }),
          excelSheetName: `${Translate({ content: 'report.monthlyInterpretationTypeDistribution' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'monthYear',
              title: Translate({ content: 'report.month' }),
              format: '{0:yyyy MMM}',
              locked: true,
              width: '300px',
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
            },
          ],
          groupColumns: 'Month,InterpretationType',
          groupTable: {
            take: 10,
            group: [
              {
                field: 'monthYear',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
              {
                field: 'interpretationType',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
            ],
          },
          type: 'property group',
          columGroup: { aggregateOn: 'monthYear', aggregateElements: ['noOfAssignments'] },
          chart: {
            categoryField: 'monthYear',
            field: 'invoicedAmount',
            type: 1,
            title: Translate({ content: 'report.monthlyInterpretationTypeDistribution' }),
          },
        },
        {
          id: 2,
          title: Translate({ content: 'report.monthlyDistribution' }),
          excelSheetName: `${Translate({ content: 'report.monthlyDistribution' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'skill',
              locked: true,
              sortable: true,
              width: '300px',
              showOnGrid: true,
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.skill' }),
            },
          ],
          groupColumns: 'Month,Skill',
          groupTable: {
            take: 10,
            group: [
              {
                field: 'skill',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
              {
                field: 'monthYear',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'skill',
            field: ['noOfAssignments', 'invoicedAmount'],
            type: 2,
            title: Translate({ content: 'report.competenceDistributionPerSkill' }),
          },
          type: 'distribute property group',
          columGroup: { aggregateOn: 'skill', aggregateElements: ['noOfAssignments', 'invoicedAmount'] },
        },
      ],
    },
    2: {
      id: 2,
      title: 'report.distributionPerInterpretation',
      range: {
        from: moment().subtract(1, 'month').startOf('month').toDate(),
        to: moment().startOf('month').toDate(),
      },
      dashboard: {
        categoryField: 'interpretationType',
        field: 'noOfAssignments',
        title: Translate({ content: 'report.distributionPerInterpretation' }),
        tableSourceId: 0,
      },
      excelFileName: `${Translate({ content: 'report.interpretationTypeDistributionPerSkill' })}-report.xlsx`,
      dataSources: [1],
      isTableLeveldataSource: true,
      tables: [
        {
          id: 0,
          title: Translate({ content: 'report.distributionPerInterpretation' }),
          excelSheetName: `${Translate({ content: 'report.perInterpretationType' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'interpretationType',
              title: Translate({ content: 'report.interpreterType' }),
              locked: true,
              width: '300px',
              showOnGrid: true,
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'invoicedAmount',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.invoicedAmount' }),
            },
          ],
          groupColumns: 'InterpretationType',
          groupTable: {
            take: 10,
            group: [
              {
                field: 'interpretationType',
                aggregates: [
                  {
                    aggregate: 'sum',
                    field: 'noOfAssignments',
                  },
                  {
                    aggregate: 'sum',
                    field: 'invoicedAmount',
                  },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'interpretationType',
            field: 'noOfAssignments',
            title: Translate({ content: 'report.distributionPerInterpretation' }),
          },
        },
        {
          id: 1,
          title: Translate({ content: 'report.interpretationTypeDistributionPerSkill' }),
          excelSheetName: `${Translate({ content: 'report.perSkill' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'skill',
              title: Translate({ content: 'report.skill' }),
              locked: true,
              showOnGrid: true,
              width: '300px',
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
            },
          ],
          groupColumns: 'InterpretationType,Skill',
          groupTable: {
            take: 10,
            group: [
              {
                field: 'skill',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
              {
                field: 'interpretationType',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'skill',
            field: ['noOfAssignments', 'invoicedAmount'],
            type: 2,
            title: Translate({ content: 'report.interpretationTypeDistributionPerSkill' }),
          },
          type: 'distribute property group',
          columGroup: { aggregateOn: 'skill', aggregateElements: ['noOfAssignments', 'invoicedAmount'] },
        },
        {
          id: 2,
          title: `${Translate({ content: 'report.interpretationTypeDistribution' })} ${Translate({ content: 'report.perCompetence' })}`,
          excelSheetName: `${Translate({ content: 'report.perCompetence' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'competenceLevel',
              title: Translate({ content: 'report.perCompetence' }),
              locked: true,
              showOnGrid: true,
              width: '300px',
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
            },
          ],
          groupColumns: 'CompetenceLevel,InterpretationType',
          groupTable: {
            take: 10,
            group: [
              {
                field: 'competenceLevel',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
              {
                field: 'interpretationType',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'competenceLevel',
            field: ['noOfAssignments', 'invoicedAmount'],
            type: 2,
            title: `${Translate({ content: 'report.interpretationTypeDistribution' })} ${Translate({ content: 'report.perCompetence' })}`,
          },
          type: 'distribute property group',
          columGroup: { aggregateOn: 'competenceLevel', aggregateElements: ['noOfAssignments', 'invoicedAmount'] },
        },
      ],
    },
    3: {
      id: 3,
      title: 'report.distributionPerSkill',
      range: {
        from: moment().subtract(1, 'month').startOf('month').toDate(),
        to: moment().startOf('month').toDate(),
      },
      excelFileName: `${Translate({ content: 'report.distributionPerSkill' })}-report.xlsx`,
      dataSources: [1],
      dashboard: {
        categoryField: 'skill',
        field: 'noOfAssignments',
        title: Translate({ content: 'report.distributionPerSkill' }),
        tableSourceId: 0,
      },
      isTableLeveldataSource: true,
      tables: [
        {
          id: 0,
          title: Translate({ content: 'report.distributionPerSkill' }),
          excelSheetName: `${Translate({ content: 'report.perSkill' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'skill',
              locked: true,
              width: '300px',
              sortable: true,
              showOnGrid: true,
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.skill' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'timeSpend',
              sortable: true,
              format: '{0:n0}',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfMinutes' }),
            },
            {
              field: 'invoicedAmount',
              showOnGrid: true,
              format: '{0:n0}',
              sortable: true,
              title: Translate({ content: 'report.invoicedAmount' }),
            },
          ],
          groupColumns: 'Skill',
          groupTable: {
            take: 10,
            group: [
              {
                field: 'skill',
                aggregates: [
                  {
                    aggregate: 'sum',
                    field: 'noOfAssignments',
                  },
                  {
                    aggregate: 'sum',
                    field: 'timeSpend',
                  },
                  {
                    aggregate: 'sum',
                    field: 'invoicedAmount',
                  },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'skill',
            field: 'noOfAssignments',
            title: Translate({ content: 'report.distributionPerSkill' }),
          },
        },
        {
          id: 1,
          title: `${Translate({ content: 'report.skillDistribution' })} ${Translate({ content: 'report.perCompetence' })}`,
          excelSheetName: `${Translate({ content: 'report.perCompetence' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'competenceLevel',
              title: Translate({ content: 'report.perCompetence' }),
              locked: true,
              showOnGrid: true,
              width: '300px',
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
            },
          ],
          groupColumns: 'CompetenceLevel,Skill',
          groupTable: {
            take: 10,
            group: [
              {
                field: 'competenceLevel',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
              {
                field: 'skill',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'competenceLevel',
            field: ['noOfAssignments', 'invoicedAmount'],
            type: 2,
            title: `${Translate({ content: 'report.interpretationTypeDistribution' })} ${Translate({ content: 'report.perCompetence' })}`,
          },
          type: 'distribute property group',
          columGroup: { aggregateOn: 'competenceLevel', aggregateElements: ['noOfAssignments', 'invoicedAmount'] },
        },
        {
          id: 2,
          title: `${Translate({ content: 'report.skillDistribution' })} ${Translate({ content: 'report.perInterpretationType' })}`,
          excelSheetName: `${Translate({ content: 'report.perInterpretationType' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'interpretationType',
              title: Translate({ content: 'report.perInterpretationType' }),
              locked: true,
              showOnGrid: true,
              width: '300px',
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
            },
          ],
          groupColumns: 'InterpretationType,Skill',
          groupTable: {
            take: 10,
            group: [
              {
                field: 'interpretationType',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
              {
                field: 'skill',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'interpretationType',
            field: ['noOfAssignments', 'invoicedAmount'],
            type: 2,
            title: `${Translate({ content: 'report.interpretationTypeDistribution' })} ${Translate({ content: 'report.perInterpretationType' })}`,
          },
          type: 'distribute property group',
          columGroup: { aggregateOn: 'interpretationType', aggregateElements: ['noOfAssignments', 'invoicedAmount'] },
        },
      ],
    },
    4: {
      id: 4,
      title: 'report.distributionPerCompetence',
      range: {
        from: moment().subtract(1, 'month').startOf('month').toDate(),
        to: moment().startOf('month').toDate(),
      },
      excelFileName: `${Translate({ content: 'report.distributionPerCompetence' })}-report.xlsx`,
      dataSources: [1],
      dashboard: {
        categoryField: 'competenceLevel',
        field: 'noOfAssignments',
        title: Translate({ content: 'report.distributionPerCompetence' }),
        tableSourceId: 0,
      },
      isTableLeveldataSource: true,
      tables: [
        {
          id: 0,
          excelSheetName: Translate({ content: 'report.perCompetence' }),
          title: Translate({
            content: 'report.distributionPerCompetence',
          }),
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'competenceLevel',
              locked: true,
              sortable: true,
              showOnGrid: true,
              width: '300px',
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.competence' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'timeSpend',
              sortable: true,
              showOnGrid: true,
              title: Translate({ content: 'report.noOfMinutes' }),
            },
            {
              field: 'invoicedAmount',
              showOnGrid: true,
              format: '{0:n0}',
              className: 'text-float-right',
              sortable: true,
              title: Translate({ content: 'report.invoicedAmount' }),
            },
          ],
          groupColumns: 'CompetenceLevel',
          groupTable: {
            take: 10,
            group: [
              {
                field: 'competenceLevel',
                aggregates: [
                  {
                    aggregate: 'sum',
                    field: 'noOfAssignments',
                  },
                  {
                    aggregate: 'sum',
                    field: 'timeSpend',
                  },
                  {
                    aggregate: 'sum',
                    field: 'invoicedAmount',
                  },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'competenceLevel',
            field: 'noOfAssignments',
            title: Translate({ content: 'report.distributionPerCompetence' }),
          },
        },
        {
          id: 1,
          excelSheetName: Translate({ content: 'report.perInterpretationType' }),
          title: Translate({
            content: 'report.competenceDistributionPerInterpreterType',
          }),
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'competenceLevel',
              title: Translate({ content: 'report.competence' }),
              locked: true,
              showOnGrid: true,
              width: '300px',
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
            },
          ],
          groupColumns: 'CompetenceLevel,InterpretationType',
          groupTable: {
            take: 10,
            group: [
              {
                field: 'competenceLevel',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
              {
                field: 'interpretationType',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'competenceLevel',
            field: 'noOfAssignments',
            type: 1,
            title: Translate({ content: 'report.competenceDistributionPerInterpreterType' }),
          },
          type: 'property group',
          columGroup: { aggregateOn: 'competenceLevel', aggregateElements: ['noOfAssignments'] },
        },
        {
          id: 2,
          excelSheetName: Translate({ content: 'report.perSkill' }),
          title: Translate({
            content: 'report.competenceDistributionPerSkill',
          }),
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'skill',
              title: Translate({ content: 'report.skill' }),
              locked: true,
              showOnGrid: true,
              width: '300px',
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
            },
          ],
          groupColumns: 'CompetenceLevel,Skill',
          groupTable: {
            take: 10,
            group: [
              {
                field: 'skill',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
              {
                field: 'competenceLevel',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'skill',
            field: ['noOfAssignments', 'invoicedAmount'],
            type: 2,
            title: Translate({ content: 'report.competenceDistributionPerSkill' }),
          },
          type: 'distribute property group',
          columGroup: { aggregateOn: 'skill', aggregateElements: ['noOfAssignments', 'invoicedAmount'] },
        },
      ],
    },
    5: {
      id: 5,
      title: 'report.distributionByUnit',
      range: {
        from: moment().subtract(1, 'month').startOf('month').toDate(),
        to: moment().startOf('month').toDate(),
      },
      excelFileName: `${Translate({ content: 'report.distributionByUnit' })}-report.xlsx`,
      dataSources: [2],
      dashboard: {
        categoryField: 'clientUnitName',
        field: 'noOfAssignments',
        type: 2,
        title: Translate({ content: 'report.distributionperClientUnit' }),
        tableSourceId: 1,
      },
      tables: [
        {
          id: 0,
          title: Translate({ content: 'report.distributionperOrderUnit' }),
          excelSheetName: `${Translate({ content: 'report.distributionperOrderUnit' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'orderUnitNumber',
              locked: true,
              sortable: true,
              width: '200px',
              showOnGrid: true,
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.orderUnitNumber' }),
            },
            {
              field: 'orderUnitName',
              locked: true,
              sortable: true,
              width: '300px',
              showOnGrid: true,
              title: Translate({ content: 'report.orderUnit' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'timeSpend',
              sortable: true,
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfMinutes' }),
            },
            {
              field: 'invoicedAmount',
              showOnGrid: true,
              format: '{0:n0}',
              className: 'text-float-right',
              sortable: true,
              title: Translate({ content: 'report.invoicedAmount' }),
            },
          ],
          groupTable: {
            take: 10,
            group: [
              {
                field: 'orderUnitNumber',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'timeSpend' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
              {
                field: 'orderUnitName',
              },
            ],
          },
          chart: {
            categoryField: 'orderUnitName',
            field: 'noOfAssignments',
            title: Translate({ content: 'report.distributionperOrderUnit' }),
          },
        },
        {
          id: 1,
          title: Translate({ content: 'report.distributionperClientUnit' }),
          excelSheetName: `${Translate({ content: 'report.distributionperClientUnit' })}`,
          style: { width: '100%' },
          columnStruct: [

            {
              field: 'clientUnitName',
              locked: true,
              sortable: true,
              width: '300px',
              showOnGrid: true,
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.clientUnit' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'timeSpend',
              sortable: true,
              format: '{0:n0}',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfMinutes' }),
            },
            {
              field: 'invoicedAmount',
              showOnGrid: true,
              format: '{0:c }',
              sortable: true,
              title: Translate({ content: 'report.invoicedAmount' }),
            },
          ],
          groupTable: {
            take: 10,
            group: [
              {
                field: 'clientUnitNumber',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'timeSpend' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
              {
                field: 'clientUnitName',
              },
            ],
          },
          chart: {
            categoryField: 'clientUnitName',
            field: 'noOfAssignments',
            title: Translate({ content: 'report.distributionperClientUnit' }),
          },
        }],
    },
    6: {
      id: 6,
      title: 'report.lateCanceledDistribution',
      range: {
        from: moment().subtract(1, 'month').startOf('month').toDate(),
        to: moment().startOf('month').toDate(),
      },
      excelFileName: `${Translate({ content: 'report.lateCanceledDistribution' })}-report.xlsx`,
      dataSources: [3],
      tables: [
        {
          id: 0,
          title: Translate({ content: 'report.lateCanceledAssignmentDistributionByIntType' }),
          excelSheetName: `${Translate({ content: 'report.perInterpretationType' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'interpretationType',
              locked: true,
              sortable: true,
              width: '300px',
              showOnGrid: true,
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.interpreterType' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'invoicedAmount',
              showOnGrid: true,
              format: '{0:n0}',
              className: 'text-float-right',
              sortable: true,
              title: Translate({ content: 'report.invoicedAmount' }),
            },
          ],
          groupTable:
          {
            take: 10,
            group: [
              {
                field: 'interpretationType',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'interpretationType',
            field: 'noOfAssignments',
            title: Translate({ content: 'report.lateCanceledAssignmentDistributionByIntType' }),
          },
        },
        {
          id: 1,
          title: Translate({ content: 'report.lateCanceledAssignmentDistributionByUnit' }),
          excelSheetName: `${Translate({ content: 'report.perUnit' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'orderUnitName',
              locked: true,
              sortable: true,
              width: '300px',
              showOnGrid: true,
              title: Translate({ content: 'report.orderUnit' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'invoicedAmount',
              showOnGrid: true,
              format: '{0:n0}',
              className: 'text-float-right',
              sortable: true,
              title: Translate({ content: 'report.invoicedAmount' }),
            },
          ],
          groupTable: {
            take: 10,
            group: [
              {
                field: 'orderUnitNumber',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
              {
                field: 'orderUnitName',
              },
              {
                field: 'orderUnitReference',
              },
            ],
          },
          chart: {
            categoryField: 'orderUnitName',
            field: 'noOfAssignments',
            title: Translate({ content: 'report.lateCanceledAssignmentDistributionByUnit' }),
          },
        }],
    },
    7: {
      id: 7,
      title: 'report.travelTimeAllowanceDistribution',
      range: {
        from: moment().subtract(1, 'month').startOf('month').toDate(),
        to: moment().startOf('month').toDate(),
      },
      excelFileName: `${Translate({ content: 'report.travelTimeAllowanceDistribution' })}-report.xlsx`,
      dataSources: [4],
      tables: [
        {
          id: 0,
          title: Translate({ content: 'report.travelTimeAllowanceDistributionPerUnit' }),
          excelSheetName: `${Translate({ content: 'report.perUnit' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'orderUnitName',
              locked: true,
              sortable: true,
              showOnGrid: true,
              width: '300px',
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.orderUnit' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'kilometersCompensated',
              showOnGrid: true,
              sortable: true,
              format: '{0:n0}',
              title: Translate({ content: 'report.kilometersCompensated' }),
            },
            {
              field: 'travelCompensatedAmount',
              showOnGrid: true,
              sortable: true,
              format: '{0:n0}',
              title: Translate({ content: 'report.travelCompensatedAmount' }),
            },
            {
              field: 'travelTime',
              showOnGrid: true,
              sortable: true,
              title: Translate({ content: 'report.travelTime' }),
            },
          ],
          groupTable: {
            take: 10,
            group: [
              {
                field: 'orderUnitNumber',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'kilometersCompensated' },
                  { aggregate: 'sum', field: 'travelCompensatedAmount' },
                  { aggregate: 'sum', field: 'travelTime' },
                ],
              },
              {
                field: 'orderUnitName',
              },
              {
                field: 'orderUnitReference',
              },
            ],
            chart: {
              categoryField: 'orderUnitName',
              field: 'noOfAssignments',
              title: Translate({ content: 'report.travelTimeAllowanceDistributionPerUnit' }),
            },
          },
          chart: {
            categoryField: 'orderUnitName',
            field: 'noOfAssignments',
            title: `${Translate({ content: 'report.travelTimeAllowanceDistributionPerUnit' })}`,
          },
        },
        {
          id: 1,
          title: `${Translate({ content: 'report.travelTimeAllowanceDistribution' })} ${Translate({ content: 'report.perMonth' })}`,
          excelSheetName: `${Translate({ content: 'report.perMonth' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'monthYear',
              locked: true,
              sortable: true,
              width: '300px',
              showOnGrid: true,
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.month' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'travelCompensatedAmount',
              showOnGrid: true,
              format: '{0:n0}',
              sortable: true,
              title: Translate({ content: 'report.travelCompensatedAmount' }),
            },
            {
              field: 'travelTime',
              format: '{0:n0}',
              showOnGrid: true,
              sortable: true,
              title: Translate({ content: 'report.travelTime' }),
            },
          ],
          groupTable: {
            take: 10,
            group: [
              {
                field: 'monthYear',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'travelCompensatedAmount' },
                  { aggregate: 'sum', field: 'travelTime' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'monthYear',
            field: 'noOfAssignments',
            title: Translate({ content: 'report.travelTimeAllowanceDistribution' }),
          },
        },
        {
          id: 2,
          title: `${Translate({ content: 'report.travelTimeAllowanceDistribution' })} ${Translate({ content: 'report.perCounty' })}`,
          excelSheetName: `${Translate({ content: 'report.perCounty' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'county',
              locked: true,
              sortable: true,
              width: '300px',
              showOnGrid: true,
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.county' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'travelCompensatedAmount',
              showOnGrid: true,
              sortable: true,
              format: '{0:n0}',
              title: Translate({ content: 'report.travelCompensatedAmount' }),
            },
            {
              field: 'travelTime',
              showOnGrid: true,
              sortable: true,
              format: '{0:n0}',
              title: Translate({ content: 'report.travelTime' }),
            },
          ],
          groupTable: {
            take: 10,
            group: [
              {
                field: 'county',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'travelCompensatedAmount' },
                  { aggregate: 'sum', field: 'travelTime' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'county',
            field: 'noOfAssignments',
            title: `${Translate({ content: 'report.travelTimeAllowanceDistribution' })} ${Translate({ content: 'report.perCounty' })}`,
          },
        },
        {
          id: 3,
          title: `${Translate({ content: 'report.travelTimeAllowanceDistribution' })} ${Translate({ content: 'report.perPostalCity' })}`,
          excelSheetName: ` ${Translate({ content: 'report.perPostalCity' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'postalCity',
              locked: true,
              sortable: true,
              width: '300px',
              showOnGrid: true,
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.postalCity' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'travelCompensatedAmount',
              showOnGrid: true,
              format: '{0:n0}',
              sortable: true,
              title: Translate({ content: 'report.travelCompensatedAmount' }),
            },
            {
              field: 'travelTime',
              showOnGrid: true,
              format: '{0:n0}',
              sortable: true,
              title: Translate({ content: 'report.travelTime' }),
            },
          ],
          groupTable: {
            take: 10,
            group: [
              {
                field: 'postalCity',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'travelCompensatedAmount' },
                  { aggregate: 'sum', field: 'travelTime' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'postalCity',
            field: 'noOfAssignments',
            title: `${Translate({ content: 'report.travelTimeAllowanceDistribution' })} ${Translate({ content: 'report.perPostalCity' })}`,
          },
        },
      ],
    },
    8: {
      id: 8,
      title: 'report.emergencyPreBookedDistribution',
      range: {
        from: moment().subtract(1, 'month').startOf('month').toDate(),
        to: moment().startOf('month').toDate(),
      },
      excelFileName: `${Translate({ content: 'report.emergencyPreBookedDistribution' })}-report.xlsx`,
      dataSources: [5],
      tables: [
        {
          id: 0,
          title: `${Translate({ content: 'report.emergencyPreBookedDistribution' })} ${Translate({ content: 'report.perUnit' })}`,
          excelSheetName: Translate({ content: 'report.perUnit' }),
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'orderUnitNumber',
              locked: true,
              sortable: true,
              showOnGrid: true,
              width: '200px',
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.orderUnitNumber' }),
            },
            {
              field: 'orderUnitName',
              locked: true,
              width: '300px',
              sortable: true,
              showOnGrid: true,
              title: Translate({ content: 'report.orderUnit' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'invoicedAmount',
              showOnGrid: true,
              format: '{0:n0}',
              sortable: true,
              title: Translate({ content: 'report.invoicedAmount' }),
            },
          ],
          groupTable: {
            take: 10,
            group: [
              {
                field: 'orderUnitNumber',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
              {
                field: 'orderUnitName',
              },
              {
                field: 'orderUnitReference',
              },
            ],
          },
          chart: {
            categoryField: 'orderUnitName',
            field: 'noOfAssignments',
            title: `${Translate({ content: 'report.emergencyPreBookedDistribution' })} ${Translate({ content: 'report.perUnit' })}`,
          },
        },
        {
          id: 1,
          title: `${Translate({ content: 'report.emergencyPreBookedDistribution' })} ${Translate({ content: 'report.perSkill' })}`,
          excelSheetName: ` ${Translate({ content: 'report.perSkill' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'skill',
              locked: true,
              width: '300px',
              sortable: true,
              showOnGrid: true,
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.skill' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'invoicedAmount',
              showOnGrid: true,
              format: '{0:n0}',
              sortable: true,
              title: Translate({ content: 'report.invoicedAmount' }),
            },
          ],
          groupTable: {
            take: 10,
            group: [
              {
                field: 'skill',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'skill',
            field: 'noOfAssignments',
            title: `${Translate({ content: 'report.emergencyPreBookedDistribution' })} ${Translate({ content: 'report.perSkill' })}`,
          },
        },
        {
          id: 2,
          title: `${Translate({ content: 'report.emergencyPreBookedDistribution' })} ${Translate({ content: 'report.perInterpretationType' })}`,
          excelSheetName: `${Translate({ content: 'report.perInterpretationType' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'interpretationType',
              locked: true,
              width: '300px',
              sortable: true,
              showOnGrid: true,
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.interpreterType' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'invoicedAmount',
              showOnGrid: true,
              format: '{0:n0}',
              sortable: true,
              title: Translate({ content: 'report.invoicedAmount' }),
            },
          ],
          groupTable: {
            take: 10,
            group: [
              {
                field: 'interpretationType',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'interpretationType',
            field: 'noOfAssignments',
            title: `${Translate({ content: 'report.emergencyPreBookedDistribution' })} ${Translate({ content: 'report.perInterpretationType' })}`,
          },
        },
        {
          id: 3,
          title: `${Translate({ content: 'report.emergencyPreBookedDistribution' })} ${Translate({ content: 'report.perCompetence' })}`,
          excelSheetName: `${Translate({ content: 'report.perCompetence' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'competenceLevel',
              locked: true,
              width: '300px',
              sortable: true,
              showOnGrid: true,
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.competence' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'invoicedAmount',
              showOnGrid: true,
              format: '{0:n0}',
              sortable: true,
              title: Translate({ content: 'report.invoicedAmount' }),
            },
          ],
          groupTable: {
            take: 10,
            group: [
              {
                field: 'competenceLevel',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'competenceLevel',
            field: 'noOfAssignments',
            title: `${Translate({ content: 'report.emergencyPreBookedDistribution' })} ${Translate({ content: 'report.perCompetence' })}`,
          },
        },
        {
          id: 4,
          title: `${Translate({ content: 'report.emergencyPreBookedDistribution' })} ${Translate({ content: 'report.perSkill' })}`,
          excelSheetName: Translate({ content: 'report.perSkill' }),
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'skill',
              locked: true,
              width: '300px',
              sortable: true,
              showOnGrid: true,
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.skill' }),
            },
          ],
          groupTable: {
            take: 10,
            group: [
              {
                field: 'skill',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
              {
                field: 'interpretationType',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'invoicedAmount' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'skill',
            field: ['noOfAssignments', 'invoicedAmount'],
            type: 2,
            title: `${Translate({ content: 'report.emergencyPreBookedDistribution' })} ${Translate({ content: 'report.perSkill' })}`,
          },
          type: 'distribute property group',
          columGroup: { aggregateOn: 'skill', aggregateElements: ['noOfAssignments', 'invoicedAmount'] },
        },
      ],
    },
    9: {
      id: 9,
      title: 'report.deviationsDistribution',
      range: {
        from: moment().subtract(1, 'month').startOf('month').toDate(),
        to: moment().startOf('month').toDate(),
      },
      excelFileName: `${Translate({ content: 'report.deviationsDistribution' })}-report.xlsx`,
      dataSources: [6],
      tables: [
        {
          id: 0,
          title: Translate({ content: 'report.distributionPerComplaintCause' }),
          excelSheetName: `${Translate({ content: 'report.perComplaintCause' })}`,
          style: { width: '47%', float: 'left' },
          columnStruct: [
            {
              field: 'complaintCause',
              locked: true,
              sortable: true,
              showOnGrid: true,
              width: '300px',
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.complaintCause' }),
            },
            {
              field: 'noOfDeviations',
              format: '{0:n0}',
              sortable: true,
              showOnGrid: true,
              title: Translate({ content: 'report.noOfDeviations' }),
            },
          ],
          groupTable: {
            take: 10,
            group: [
              {
                field: 'complaintCause',
                aggregates: [
                  {
                    aggregate: 'sum',
                    field: 'noOfDeviations',
                  },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'complaintCause',
            field: 'noOfDeviations',
            title: Translate({ content: 'report.distributionPerComplaintCause' }),
          },
        },
        {
          id: 1,
          title: Translate({ content: 'report.distributionPerMeasureTaken' }),
          excelSheetName: `${Translate({ content: 'report.perMeasureTaken' })}`,
          style: { width: '47%', float: 'right' },
          columnStruct: [
            {
              field: 'measureTaken',
              locked: true,
              sortable: true,
              width: '300px',
              showOnGrid: true,
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.measureTaken' }),
            },
            {
              field: 'noOfDeviations',
              sortable: true,
              showOnGrid: true,
              format: '{0:n0}',
              title: Translate({ content: 'report.noOfDeviations' }),
            },
          ],
          groupTable: {
            take: 10,
            group: [
              {
                field: 'measureTaken',
                aggregates: [
                  {
                    aggregate: 'sum',
                    field: 'noOfDeviations',
                  },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'measureTaken',
            field: 'noOfDeviations',
            title: Translate({ content: 'report.distributionPerMeasureTaken' }),
          },
        },
        {
          id: 2,
          title: Translate({ content: 'report.distributionperOrderUnit' }),
          excelSheetName: `${Translate({ content: 'report.perUnit' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'orderUnitName',
              locked: true,
              sortable: true,
              width: '300px',
              showOnGrid: true,
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.orderUnitName' }),
            },
            {
              field: 'noOfDeviations',
              sortable: true,
              showOnGrid: true,
              format: '{0:n0}',
              title: Translate({ content: 'report.noOfDeviations' }),
            },
          ],
          groupTable: {
            take: 10,
            group: [{
              field: 'orderUnitNumber',
              aggregates: [
                { aggregate: 'sum', field: 'noOfDeviations' },

              ],
            },
            {
              field: 'orderUnitName',
            },
            ],
          },
          chart: {
            categoryField: 'orderUnitName',
            field: 'noOfDeviations',
            title: Translate({ content: 'report.distributionperOrderUnit' }),
          },
        },
        {
          id: 3,
          title: Translate({ content: 'report.distributionperOrderUnit' }),
          excelSheetName: `${Translate({ content: 'report.perUnit' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'orderUnitName',
              locked: true,
              width: '300px',
              sortable: true,
              showOnGrid: true,
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.orderUnitName' }),
            },
            {
              field: 'noOfDeviations',
              sortable: true,
              showOnGrid: true,
              format: '{0:n0}',
              title: Translate({ content: 'report.noOfDeviations' }),
            },
            {
              field: 'approvedPenaltyAmount',
              sortable: true,
              showOnGrid: true,
              format: '{0:n0}',
              title: Translate({ content: 'report.approvedPenaltyAmount' }),
            },
            {
              field: 'invoicedPenaltyAmount',
              sortable: true,
              showOnGrid: true,
              format: '{0:n0}',
              title: Translate({ content: 'report.invoicedPenaltyAmount' }),
            },
          ],
          groupTable: {
            take: 10,
            group: [{
              field: 'orderUnitNumber',
              aggregates: [
                { aggregate: 'sum', field: 'noOfDeviations' },
                { aggregate: 'sum', field: 'approvedPenaltyAmount' },
                { aggregate: 'sum', field: 'invoicedPenaltyAmount' },

              ],
            },
            {
              field: 'orderUnitName',
            },
            ],
          },
          chart: {
            categoryField: 'orderUnitName',
            field: 'approvedPenaltyAmount',
            title: Translate({ content: 'report.distributionperOrderUnit' }),
          },
        },
      ],
    },
    10: {
      id: 10,
      title: 'report.fulFillmentDistribution',
      range: {
        from: moment().subtract(1, 'month').startOf('month').toDate(),
        to: moment().startOf('month').toDate(),
      },
      excelFileName: `${Translate({ content: 'report.distributionPerSkill' })}-report.xlsx`,
      dataSources: [7],
      isTableLeveldataSource: true,
      tables: [
        {
          id: 0,
          title: Translate({ content: 'report.distributionByUnit' }),
          excelSheetName: `${Translate({ content: 'report.perUnit' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'orderUnitNumber',
              locked: true,
              sortable: true,
              showOnGrid: true,
              width: '200px',
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.orderUnitNumber' }),
            },
            {
              field: 'orderUnitName',
              locked: true,
              sortable: true,
              showOnGrid: true,
              format: '{0:n0}',
              width: '300px',
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.orderUnitName' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'accepted',
              sortable: true,
              format: '{0:n0}',
              showOnGrid: true,
              title: Translate({ content: 'report.accepted' }),
            },
            {
              field: 'cancelledByCustomer',
              sortable: true,
              format: '{0:n0}',
              showOnGrid: true,
              title: Translate({ content: 'report.canceledByCustomer' }),
            },
            {
              field: 'notFulFilled',
              sortable: true,
              format: '{0:n0}',
              showOnGrid: true,
              title: Translate({ content: 'report.notFulFilled' }),
            },
          ],
          groupColumns: 'OrderUnitNumber,OrderUnitName',
          groupTable: {
            take: 10,
            group: [
              {
                field: 'orderUnitNumber',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'ordered' },
                  { aggregate: 'sum', field: 'accepted' },
                  { aggregate: 'sum', field: 'cancelledByCustomer' },
                  { aggregate: 'sum', field: 'notFulFilled' },
                ],
              },
              {
                field: 'orderUnitName',
              },
            ],
          },
          chart: {
            categoryField: 'orderUnitName',
            field: 'notFulFilled',
            type: 3,
            title: Translate({ content: 'report.distributionByUnit' }),
          },
        },
        {
          id: 1,
          title: Translate({ content: 'report.distributionPerInterpretation' }),
          excelSheetName: `${Translate({ content: 'report.perInterpretationType' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'interpretationType',
              locked: true,
              sortable: true,
              showOnGrid: true,
              width: '300px',
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.interpreterType' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'accepted',
              sortable: true,
              format: '{0:n0}',
              showOnGrid: true,
              title: Translate({ content: 'report.accepted' }),
            },
            {
              field: 'cancelledByCustomer',
              sortable: true,
              format: '{0:n0}',
              showOnGrid: true,
              title: Translate({ content: 'report.canceledByCustomer' }),
            },
            {
              field: 'notFulFilled',
              sortable: true,
              format: '{0:n0}',
              showOnGrid: true,
              title: Translate({ content: 'report.notFulFilled' }),
            },
          ],
          groupColumns: 'InterpretationType',
          groupTable: {
            take: 10,
            group: [
              {
                field: 'interpretationType',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'ordered' },
                  { aggregate: 'sum', field: 'completed' },
                  { aggregate: 'sum', field: 'deviation' },
                  { aggregate: 'sum', field: 'fullfiled' },
                  { aggregate: 'sum', field: 'acceptedAndCancelled' },
                  { aggregate: 'sum', field: 'acceptedNotCancelledNotCompleted' },
                  { aggregate: 'sum', field: 'notAccepted' },
                  { aggregate: 'sum', field: 'accepted' },
                  { aggregate: 'sum', field: 'cancelledByCustomer' },
                  { aggregate: 'sum', field: 'notFulFilled' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'interpretationType',
            field: 'notFulFilled',
            title: Translate({ content: 'report.distributionPerInterpretation' }),
          },
        },
        {
          id: 2,
          title: Translate({ content: 'report.distributionPerSkill' }),
          excelSheetName: `${Translate({ content: 'report.perSkill' })}`,
          style: { width: '100%' },
          columnStruct: [
            {
              field: 'skill',
              locked: true,
              sortable: true,
              showOnGrid: true,
              width: '300px',
              footerCell: props => (<td colSpan={props.colSpan} style={{ ...props.style, color: '#000' }}><Translate content="report.totalsum" /></td>),
              title: Translate({ content: 'report.skill' }),
            },
            {
              field: 'noOfAssignments',
              sortable: true,
              format: '{0:n0}',
              className: 'text-float-right',
              showOnGrid: true,
              title: Translate({ content: 'report.noOfAssignments' }),
            },
            {
              field: 'accepted',
              sortable: true,
              format: '{0:n0}',
              showOnGrid: true,
              title: Translate({ content: 'report.accepted' }),
            },
            {
              field: 'cancelledByCustomer',
              sortable: true,
              format: '{0:n0}',
              showOnGrid: true,
              title: Translate({ content: 'report.canceledByCustomer' }),
            },
            {
              field: 'notFulFilled',
              sortable: true,
              format: '{0:n0}',
              showOnGrid: true,
              title: Translate({ content: 'report.notFulFilled' }),
            },
          ],
          groupColumns: 'Skill',
          groupTable: {
            take: 10,
            group: [
              {
                field: 'skill',
                aggregates: [
                  { aggregate: 'sum', field: 'noOfAssignments' },
                  { aggregate: 'sum', field: 'ordered' },
                  { aggregate: 'sum', field: 'accepted' },
                  { aggregate: 'sum', field: 'cancelledByCustomer' },
                  { aggregate: 'sum', field: 'notFulFilled' },
                ],
              },
            ],
          },
          chart: {
            categoryField: 'skill',
            field: 'notFulFilled',
            title: Translate({ content: 'report.distributionPerSkill' }),
          },
        },
      ],
    },
  },

};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      return INITIAL_STATE;

    case actionTypes.UPDATE_REPORT_DATE_PERIOD: {
      const { reportIds, range } = action;
      const newReports = { ...state.reports };
      reportIds.forEach((reportId) => {
        newReports[reportId] = {
          ...newReports[reportId],
          range: {
            from: range.from,
            to: range.to,
          },
        };
      });
      return {
        ...state,
        reports: { ...newReports },
      };
    }
    case actionTypes.UPDATE_REPORT_TABLES: {
      const { reportId, tables } = action;
      const newReports = { ...state.reports };

      newReports[reportId] = {
        ...newReports[reportId],
        tables,
      };


      return {
        ...state,
        reports: { ...newReports },
      };
    }
    default:
      return state;
  }
};

export default app;
