import React from 'react';
import PropTypes from 'prop-types';
import IfComponent from '../IfComponent';

const propTypes = {
  children: PropTypes.node,
  button: PropTypes.node,
  className: PropTypes.string,
  onBlur: PropTypes.func,
};

const defaultProps = {
  children: null,
  button: null,
  className: 'tv-filter-list__element',
  onBlur: () => { },
};

class DropdownMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showMenu: false };
  }

  componentDidMount() {
    this.ismounted = true;
  }

  componentWillUnmount() {
    this.ismounted = false;
  }

    onHiddenMenu = () => {
      if (this.ismounted) {
        this.setState({
          showMenu: false,
        });
      }
    }

    onToggleMenu = () => {
      const { showMenu } = this.state;
      this.setState({
        showMenu: !showMenu,
      });
    }

    onBlur = (event) => {
      const { currentTarget } = event;
      setTimeout(() => {
        if (!currentTarget.contains(document.activeElement)) {
          this.onHiddenMenu();
        }
      }, 250);
      const { onBlur } = this.props;
      onBlur();
    }

    render() {
      const { showMenu } = this.state;
      const { children, button, className } = this.props;

      return (
        <span onBlur={this.onBlur} className={className}>
          <span role="button" tabIndex="-1" onClick={this.onToggleMenu} onKeyDown={() => {}}>
            {(button)}
          </span>
          <IfComponent
            condition={showMenu}
            whenTrue={(children)}
          />
        </span>
      );
    }
}

DropdownMenu.propTypes = propTypes;
DropdownMenu.defaultProps = defaultProps;

export default DropdownMenu;

