import moment from 'moment';
import { toast } from 'react-toastify';
import {
  filter,
  sortBy,
  isEmpty,
} from 'lodash/core';
import { status, MULTI_FORM, GLOBAL_DATE_FORMAT, availabilityStatus } from '../constants';
import { getNearest5MinutesInterval } from './dateUtil';
import history from './history';
import { Translate } from '../components';
import { serviceLog, logApiLevel } from './logUtil';

export { logApiLevel } from './logUtil';

export const isDebugMode = localStorage.getItem('debug') === 'true';

export function consoleLog(message, type = 'log') {
  if (isDebugMode) {
    const fn = console[type];
    if (console && fn) {
      fn(message);
    }
  }
}

export const isIE = !!document.documentMode || window.navigator.userAgent.indexOf('Edge') > -1 || false;

export function transvoiceLog(message, type = logApiLevel.info) {
  if (type === logApiLevel.info) {
    consoleLog(message, 'log');
  } else if (type === logApiLevel.error) {
    serviceLog(message, type);
  }
}

export function getCurrentDomain() {
  const { protocol, hostname } = window.location;
  const port = window.location.port ? `:${window.location.port}` : '';

  return `${protocol}//${hostname}${port}`;
}

export function redirectToDashBoard() {
  if (window.location.href.indexOf('/login') > -1) {
    history.push('/');
  }
}

// type: success, info, warn, error,
export function showToastMessage(type, message) {
  toast[type](message);
}

export function assignmentTimeFormat(
  from,
  to,
  format = `${Translate({ content: 'setAvailability.from' })} {from} - ${Translate({ content: 'setAvailability.to' })} {to} - {day}`,
) {
  const date_format = GLOBAL_DATE_FORMAT; // 'MMM Do YYYY';
  const time_format = 'HH:mm';
  const fromdt = moment(from);
  const todt = moment(to);
  const allday = todt.clone().startOf('day').diff(fromdt.clone().startOf('day'), 'days') > 0;

  const reFormat = allday ? `${Translate({ content: 'setAvailability.from' })} {from} {dayFrom} - ${
    Translate({ content: 'setAvailability.to' })} {to} {dayTo}` : `${
    Translate({ content: 'setAvailability.from' })} {from} - ${
    Translate({ content: 'setAvailability.to' })} {to} - {day}`;

  return allday ? reFormat.replace(/{from}/g, fromdt.format(time_format).replace(' ', ''))
    .replace(/{dayFrom}/g, fromdt.format(date_format))
    .replace(/{to}/g, todt.format(time_format).replace(' ', ''))
    .replace(/{dayTo}/g, todt.format(date_format))
    :
    reFormat.replace(/{from}/g, fromdt.format(time_format).replace(' ', ''))
      .replace(/{to}/g, todt.format(time_format).replace(' ', ''))
      .replace(/{day}/g, fromdt.format(date_format));
}

export function getRangeOfDates(start, end, key, arr = [start.startOf(key)]) {
  if (start.isAfter(end)) throw new Error('start must precede end');
  const next = moment(start).add(1, key).startOf(key);
  if (next.isAfter(end, key)) return arr;
  return getRangeOfDates(next, end, key, arr.concat(next));
}

export function getDateRangeFromWeek(week = moment().week(), year = moment().year()) {
  const newWeek = moment().year(year).week(week);
  const clone = newWeek.clone();
  return {
    end: newWeek.endOf('isoWeek'),
    start: clone.startOf('isoWeek'),
  };
}

export function getDates(startDate, endDate, notWeekend = false) {
  let dates = [];
  let currentDate = startDate;
  const addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays.call(currentDate, 1);
  }
  if (notWeekend) {
    dates = dates.filter(x => moment(x).day() !== 0 && moment(x).day() !== 6);
  }
  return dates;
}

export function get24HoursInday() {
  const hours = [];
  const hoursPerDay = 24;
  for (let i = 0; i < hoursPerDay; i += 1) {
    const formattedTime = moment(i, 'hh').format('H:00');
    hours.push(formattedTime);
  }
  return hours;
}

export function generateUniqueId() {
  return Math.random().toString(36).substring(2) +
    (new Date()).getTime().toString(36);
}

export function generateDefaultSession() {
  return {
    ranges: {
      startDate: moment().add(1, 'days'),
      endDate: moment().add(1, 'days'),
      key: generateUniqueId(),
    },
    startTime: getNearest5MinutesInterval().add(5, 'minutes'),
    endTime: getNearest5MinutesInterval().add(65, 'minutes'),
  };
}

export function filterEventInDay(events, date) {
  const result = filter(events, e => moment(e.from).isSame(date, 'day'));
  return result.length > 0 ?
    sortBy(result, e => moment.duration(e.from.diff(e.to)).asMinutes()) :
    result;
}

export function filterEventInWeek(events, range) {
  const result = filter(events, e => moment(e.from).isBetween(range[0], range[range.length - 1], null, '()'));
  return result.length > 0 ?
    sortBy(result, e => moment.duration(e.from.diff(e.to)).asMinutes()) :
    result;
}
export function filterAvailabilityInDay(availabilities, date) {
  const result = availabilities.filter(x => moment(x.DatetimeFrom).isSame(date, 'day') || (date.isBetween(moment(x.DatetimeFrom), moment(x.DatetimeTo), 'day', '[]')));
  // filter(availabilities, a => moment(a.DateTimeFrom).isSame(date, 'day'));
  return result.length > 0 ?
    sortBy(
      result,
      e => moment.duration(moment(e.DatetimeFrom).diff(moment(e.DatetimeTo))).asMinutes(),
    ) :
    result;
}
export function preventScrollWhenVisible(isVisible) {
  const htmlDom = document.getElementsByTagName('html')[0];
  if (isVisible && !htmlDom.classList.contains('tv-tv-is-clipped')) {
    htmlDom.classList.add('tv-is-clipped');
    setTimeout(() => {
      htmlDom.classList.add('js-slide-in');
    }, 10);
  }
}

export function removeContextualClass() {
  const htmlDom = document.getElementsByTagName('html')[0];
  if (htmlDom.classList.contains('tv-is-clipped')) {
    htmlDom.classList.remove('tv-is-clipped');
    htmlDom.classList.remove('js-slide-in');
  }
}

export function Capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function CapitalizeAndLowerOther(str) {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
}

export function mergeAssignmentStatusWithLocalStatus(assignmentList, localWorkAssignment) {
  const assignmentListWithLocalStatus = [];
  assignmentList.forEach((item) => {
    const assignmentId = item.WorkAssignmentIdentifier;

    const cloneItem = { ...item };
    cloneItem.status = localWorkAssignment[assignmentId]
      ? localWorkAssignment[assignmentId].status
      : status.accepted.name;

    if (item.DatetimeSubmitted || item.CanBeTimeReported === false) {
      cloneItem.status = status.submitted.name;
    }

    // Assignment over time should have status fullfiled
    if (moment().diff(item.DatetimeOrderTo, 'seconds') > 0 && cloneItem.status !== status.submitted.name) {
      cloneItem.status = status.fullfilled.name;
    }

    if (item.DatetimeCompleted) {
      cloneItem.status = status.done.name;
    }

    if (item.CancellationStatus) {
      cloneItem.status = status.cancelled.name;
    }


    assignmentListWithLocalStatus.push(cloneItem);
  });

  return assignmentListWithLocalStatus;
}

export function endWith(target, search) {
  if (search.length > target.length) return false;
  return target.substring(target.length - search.length, target.length) === search;
}

export function createDefaultAssignment(type, skills = [], service = [], contactPersons = [], profile = {}) {
  const initSkill = skills.length > 0 ? skills[0].SkillIdentifier : '';
  const initService = service.length > 0 ? service[0].ServiceIdentifier : '';
  const initAddress = profile ? {
    addressLine: !isEmpty(profile.customerAddress) ? profile.customerAddress.AddressRow1 : '',
    city: !isEmpty(profile.customerAddress) ? profile.customerAddress.City : '',
    postalCode: !isEmpty(profile.customerAddress) ? profile.customerAddress.PostalCode : '',
    orderCompanyName: !isEmpty(profile.customerName) ? profile.customerName : '',
    orderLocation: !isEmpty(profile.customerAddress) ?
      `${!profile.customerAddress.CareOf ? '' : `${
        profile.customerAddress.CareOf},`} ${
        profile.customerAddress.AddressRow1}, ${
        profile.customerAddress.PostalCode}, ${
        profile.customerAddress.City}` : '',
  } : {
    addressLine: '',
    city: '',
    postalCode: '',
    orderCompanyName: '',
    orderLocation: '',
  };
  const form = {
    serialDateRanges: [generateDefaultSession()],
    certificationLevels: undefined,
    isAllowLowerLevel: false,
    yourOrderNumber: '',
    yourReferenceNumber: '',
    typeOfAssignment: initService,
    addressLine: initAddress.addressLine,
    district: '',
    city: initAddress.city,
    postalCode: initAddress.postalCode,
    isHomeAddress: false,
    language: initSkill,
    languages: skills,
    otherInformation: '',
    genderRequirement: false,
    genderValue: '0',
    contactPerson: '',
    contactNumber: '',
    assignmentDescription: '',
    orderCompanyName: initAddress.orderCompanyName,
    orderContactPerson: '',
    orderContactNumber: '',
    orderContactDirectNumber: '',
    orderLocation: initAddress.orderLocation,
    orderWebsite: '',
    orderEmail: '',
    isSameAbove: false,
    isSameProfile: true,
    profile: {},
    files: [],
    services: service,
    contactPersons,
    contactDirectNumber: '',
    contactEmail: '',
  };
  if (type === MULTI_FORM) {
    return {
      form,
      isValid: true,
      isShow: true,
      isSubmitted: false,
    };
  }
  return form;
}

export function convertEvent(googleEvents, phone) {
  const events = googleEvents.result.items;
  const transvoiceEvent = events.map((event) => {
    const from = event.start.dateTime ? moment(event.start.dateTime) : moment(event.start.date, 'YYYY-MM-DD');
    const to = event.end.dateTime ? moment(event.end.dateTime) : moment(event.end.date, 'YYYY-MM-DD');
    const title = event.summary;
    const { location, description } = event;
    const googleEventWithoutTime = !event.start.dateTime || !event.start.dateTime;
    return {
      type: 'custom',
      from,
      to,
      title,
      location,
      description,
      googleEventWithoutTime,
      AvailabilityType: availabilityStatus.AVAILABLE,
      DatetimeFrom: from,
      DatetimeTo: to,
      id: event.id,
    };
  });

  return transvoiceEvent;
}

export function createDefaultContactPerson() {
  const form = {
    FirstName: null,
    LastName: null,
    PhoneNumberDirect: null,
    PhoneNumberMobile: null,
    Title: null,
    Email: null,
  };
  return form;
}

export function mergeAvailability(availabilitList, selectedCalendarDay) {
  const availabilities = availabilitList.map(x => ({
    ...x,
    timeFrom: `${selectedCalendarDay.format('YYYY-MM-DD')} ${moment(x.DatetimeFrom).format('HH:mm:ss')}`,
    timeTo: `${selectedCalendarDay.format('YYYY-MM-DD')} ${moment(x.DatetimeTo).format('HH:mm:ss')}`,
    noOfDays: moment(x.DatetimeTo).diff(moment(x.DatetimeFrom), 'day') + 1,
  }));
  const mergedAvailabilities = [];
  let mergedAvaialabilityIdentifieres = [];
  availabilities.map((x) => {
    if (mergedAvaialabilityIdentifieres.indexOf(x.identifier) < 0) {
      const listOfAvailabilitiesWithinTimePeriod = availabilities.filter(y => 
        (x.identifier !== y.identifier && 
        moment(y.timeFrom) <= moment(x.timeTo) &&
         moment(x.timeFrom) <= moment(y.timeFrom)  &&
         moment(y.timeTo) <= moment(x.timeTo)  && x.articleType == y.articleType &&
 x.availableFor === y.availableFor)) || [];
      mergedAvaialabilityIdentifieres = [...mergedAvaialabilityIdentifieres, ...listOfAvailabilitiesWithinTimePeriod.map(y => y.identifier)];
      x.mergedAvailabilities = listOfAvailabilitiesWithinTimePeriod || [];
      mergedAvailabilities.push(x);
    }
  });

  return mergedAvailabilities.sort((a, b) => (moment(b.timeFrom).diff(moment(a.timeFrom)) < 0));
}
