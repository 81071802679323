import React from 'react';
import { Redirect } from "react-router-dom";

const ContactTransvoice = (props) => {
  return (
    <Redirect
      to={{
        pathname: '/settings',
        state: { from: props.location },
      }}
    />
  );
};

export default ContactTransvoice;
