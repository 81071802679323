import React from 'react';
import PropTypes from 'prop-types';
import { ValidatorComponent } from 'react-form-validator-core';
import classNames from 'classnames';

import IfComponent from '../IfComponent';

const propTypes = {
  errorMessages: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  validators: PropTypes.arrayOf(PropTypes.string),
  validatorListener: PropTypes.func,
  value: PropTypes.string,
  multiline: PropTypes.bool,
  className: PropTypes.string,
  rest: PropTypes.shape({}),
};

const defaultProps = {
  errorMessages: [],
  validators: ['matchRegexp:(.*)'],
  validatorListener: () => {},
  value: '',
  multiline: false,
  className: '',
  rest: {},
};

class TextField extends ValidatorComponent {
  render() {
    const {
      errorMessages, validators, validatorListener,
      value, multiline, className, ...rest
    } = this.props;

    const { isValid } = this.state;
    const controlClassName = classNames({
      'tv-form__control': true,
      'tv-form__control--error': !isValid,
      [className]: !!className,
    });

    return (
      <React.Fragment>
        <IfComponent
          condition={!!multiline}
          whenTrue={(
            <textarea
              className={controlClassName}
              value={value || ''}
              {...rest}
              ref={(r) => { this.input = r; }}
            />
          )}
          whenFalse={(
            <input
              className={controlClassName}
              value={value || ''}
              {...rest}
              ref={(r) => { this.input = r; }}
            />
          )}
        />
        <span className="tv-form__error">
          {(typeof isValid === 'boolean' && !isValid) ?
            this.getErrorMessage() : ''}
        </span>
      </React.Fragment>
    );
  }
}

TextField.propTypes = propTypes;
TextField.defaultProps = defaultProps;

export default TextField;
