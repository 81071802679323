import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};

const defaultProps = {
  text: '',
  onChange: () => { },
  className: '',
  disabled: false,
  checked: false,
};

const TransvoiceCheckbox = ({
  text, onChange, className, disabled, checked,
}) => (
  <label className={`tv-form__checkbox-container ${className}`}>
    <input disabled={disabled} type="checkbox" onChange={onChange} defaultChecked={checked} />
    <span className="tv-form__checkbox-checkmark" />
    {text}
  </label>
);

TransvoiceCheckbox.propTypes = propTypes;
TransvoiceCheckbox.defaultProps = defaultProps;

export default TransvoiceCheckbox;
