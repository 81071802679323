import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import composeWithDevTools from 'remote-redux-devtools';
import rootReducer from '../reducers';

// use for redux dev tool on chrome

const composeEnhancers =
  typeof window === 'object' &&
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace:true}) : compose;

// add configuration for thunk
const middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
  // middleware.push(createLogger());
}

// use for redux dev tool on chrome
const enhancer = composeEnhancers(applyMiddleware(...middleware));

const store = createStore(
  rootReducer,
  enhancer,
);

export default store;