import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';

import {
  ChangeReportDatePeriod,
} from '../../actions';

import { Translate } from '../../../Share/components';
import { AgreementFilter, DataSourceFilter } from '..';


const propTypes = {
  reportId: PropTypes.number.isRequired,
  children: PropTypes.node,
  style: PropTypes.shape({}),
  saveExcel: PropTypes.func,
  OnDateRangeChange: PropTypes.func,
  dateRange: PropTypes.shape({ from: {}, to: {} }),
};
const defaultProps = {
  children: null,
  style: {},
  dateRange: { from: {}, to: {} },
  OnDateRangeChange: () => {},
  saveExcel: () => {},
};
const INITAL_STATES = {
  reportId: 1,
  dateRange: {
    start: moment().subtract(11, 'month').toDate(),
    end: moment().subtract(1, 'month').toDate(),
  },
};
class ReportLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITAL_STATES,
      reportId: props.reportId,
      dateRange: {
        start: props.dateRange.from,
        end: props.dateRange.to,
      },
    };
    this.props.OnDateRangeChange(
      this.state.dateRange,
      this.state.reportId,
    );
  }

  handleDateChange = (event) => {
    this.setState({
      ...this.state,
      dateRange: event.target.value,
    });
  };

  render() {
    const { dateRange, reportId } = this.state;
    const { children } = this.props;
    return (
      <React.Fragment>
        <div className="dashboard__filter-holder" >

          <DateRangePicker
            format="yyyy-MM-dd"
            style={{ fontFamily: '"Campton", sans-serif' }}
            startDateInputSettings={{ label: Translate({ content: 'report.start' }) }}
            endDateInputSettings={{ label: Translate({ content: 'report.end' }) }}
            value={dateRange}
            defaultValue={dateRange}
            onChange={this.handleDateChange}
          />
          <AgreementFilter reportId={reportId} />

          <button
            title="Report Period"
            style={{ marginLeft: '10px' }}
            className="tv-buttons__element tv-buttons__element--primary"
            onClick={() => this.props.OnDateRangeChange(dateRange, reportId)}
          >
            <Translate content="report.extractReport" />
          </button>

          <button
            title="Report Period"
            style={{ marginLeft: '10px' }}
            className="tv-buttons__element tv-buttons__element--primary"
            onClick={() => this.props.saveExcel(0, true)}
          ><span className="k-icon k-i-download" style={{ marginRight: '5px' }} />
            <Translate content="report.exportReport" />
          </button>
        </div>

        <div style={{
          width: '100%', display: 'flex', flexWrap: 'wrap', gap: '12px',
        }}
        >
          {children}
        </div>
        <DataSourceFilter />
      </React.Fragment>

    );
  }
}
ReportLayout.propTypes = propTypes;
ReportLayout.defaultProps = defaultProps;


const mapStateToProps = (state, props) => ({

});

const mapDispatchToProps = dispatch => ({
  OnDateRangeChange: (dateRange, reportId) => {
    dispatch(ChangeReportDatePeriod(dateRange, reportId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportLayout);
