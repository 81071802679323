import moment from 'moment-timezone';
import { DEFAULT_DATE_FORMAT, TOTAL_DAY_OF_MONTHLY_CALENDAR } from '../constants';

export function startOfToday() {
  return moment().startOf('day').format(DEFAULT_DATE_FORMAT);
}

export function endOfToday() {
  return moment().endOf('day').format(DEFAULT_DATE_FORMAT);
}

export function currentDate() {
  return moment();
}

// 12h46 --> 12h45
// 12h57 --> 12h55
export function getNearest5MinutesInterval() {
  const current = moment().startOf('minutes');
  const minutes = current.minutes();
  const subtract = minutes % 5;
  return current.subtract(subtract, 'minutes');
}

export function convertStringToDate(dateString, format = DEFAULT_DATE_FORMAT) {
  return moment(dateString, format);
}
// Ex: amount: 1, type: "days" | amount: 2, type: "months"
export function getXTimefromToday(amount, type) {
  return moment().add(amount, type);
}

// Ex: amount: 1, type: "days" | amount: 2, type: "months"
export function getXTimefromSpecificDate(date, amount, type) {
  return moment(date).add(amount, type);
}

// // fromBackend === true --> convert date from backend to time zone local
// // fromBackend === fale --> convert date from local to backend timezone
// export function formatTimezone(time, fromBackend = false, format = DEFAULT_DATE_FORMAT) {
//   const convertFromTime = moment.tz(time, fromBackend ? timezoneBE : moment.tz.guess());
//   const convertToTime = convertFromTime.clone().tz(fromBackend ? moment.tz.guess() : timezoneBE);
//   return moment(convertToTime).format(format);
// }

// export function convertFromBackendTzToLocalTz(time) {
//   return formatTimezone(time, true);
// }

// export function convertFromLocalTzToBackend(time) {
//   return formatTimezone(time, false);
// }

export function getCurrentDateWithFormat() {
  return moment().format('ddd, DD MMM YYYY');
}

export function formatTimeForAvailability(momentObj, format = DEFAULT_DATE_FORMAT) {
  return moment(momentObj).format(format);
}

export function joinTimeForAvailability(momentDay, momentHour) {
  return `${moment(momentDay).format('YYYY-MM-DD')} ${moment(momentHour).format('HH:mm')}`;
}

export function checkTimeForCalled(dateInput, secondPlus) {
  const dateCheck = dateInput.clone().add(secondPlus, 'seconds');
  return dateCheck.diff(currentDate(), 'seconds') < 0;
}

export function getFirstDayAndLastDayOnMonthlyCalendarView(year, month) {
  const currentMonth = moment({
    year,
    month,
  });

  const firstOfMonth = currentMonth.clone().startOf('month');
  const firstDayOfMonth = (new Date(year, month)).getDay();
  const firstOfMonthlyCalendarView = firstOfMonth.clone().subtract(firstDayOfMonth, 'days');

  const extraDayOnMonthlyCalendar = TOTAL_DAY_OF_MONTHLY_CALENDAR - (currentMonth.daysInMonth()) - firstDayOfMonth;
  const lastDayOfMonth = currentMonth.clone().endOf('month');
  const lastOfMonthCalendarView = lastDayOfMonth.clone().add(extraDayOnMonthlyCalendar, 'days');

  return {
    firstOfMonthlyCalendarView,
    lastOfMonthCalendarView,
  };
}

export const isNotExpiredRequest = r => (moment(r.DatetimeExpiration, 'YYYY-MM-DD HH:mm:ss') - moment(new Date()) > 0);
