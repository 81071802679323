
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from '@progress/kendo-react-layout';
import ReportCardToolBar from './ReportCardToolbar';

const propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  style: PropTypes.shape({}),
  setColumnStruct: PropTypes.func,
  columnStruct: PropTypes.arrayOf(PropTypes.shape({})),
  handleExport: PropTypes.func,
  handleTypechange: PropTypes.func,
};
const defaultProps = {
  children: null,
  title: '',
  style: {},
  columnStruct: [],
  handleExport: () => {},
  setColumnStruct: () => {},
  handleTypechange: () => {},
};
const ReportCard = function (props, context) {
  const [gridToogle, setGridToogle] = useState(true);

  return (
    <div style={{
      width: '50vw',
      alignSelf: 'flex-start',
      alignContent: 'space-around',
      margin: '4px',
      ...props.style,
    }}
    >
      <Card style={{
        boxShadow: '0 0 4px 0 rgba(0, 0, 0, .1)', marginTop: '15px', padding: '5px', width: '100%', borderRadius: '10px',
      }}
      >
        <CardHeader>
          <ReportCardToolBar
            title={props.title}
            handleExport={props.handleExport}
            handleToogle={() => setGridToogle(!gridToogle)}
            columnStruct={props.columnStruct}
            setColumnStruct={props.setColumnStruct}
          />

        </CardHeader>
        {gridToogle ?
          props.children[0] :
          <div style={{ margin: '5px', padding: '10px', border: '1px solid #0000001f' }}>
            <div style={{ display: 'inline-block', width: '100%' }} />
            <div style={{ display: 'none' }}>
              { props.children[0]}
            </div>
            { props.children[1]}
          </div>

        }
      </Card>
    </div>);
};

ReportCard.propTypes = propTypes;
ReportCard.defaultProps = defaultProps;

export default ReportCard;
