import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { connect } from 'react-redux';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { Translate } from '../../../Share/components';
import './style.scss';

const propTypes = {
  reports: PropTypes.shape({}),
};

const defaultProps = {
  reports: {},
};


function SideBar(props) {
  const { reports } = props;
  const reportTestId = [...Object.values(reports)];

  return (
    <nav id="sidebar" className="tv-left-pannel__sidebar">
      <NavLink exact to="/" className="tv-left-pannel__sidebar-item">
        <i className="icon-home tv-left-pannel__sidebar-icon tv-icon-fixed-width" />
        <span><Translate content="sideBar.dashboard" /></span>
      </NavLink>
      <PanelBar style={{ fontFamily: "'Campton', sans-serif" }} >
        <PanelBarItem title={Translate({ content: 'report.report' })} icon="paste-plain-text" >
          {reportTestId.map(x => (
            <NavLink to={`/report/${x.id}`} className="tv-left-pannel__sidebar-item" key={x.id} style={{fontSize:'0.8rem'}}>
              <i className="icon-browse-assignments tv-left-pannel__sidebar-icon tv-icon-fixed-width" />
              <span>{Translate({ content: x.title })}</span>
            </NavLink>))
          }
        </PanelBarItem>
      </PanelBar>
      <NavLink to="/contact-transvoice" className="tv-left-pannel__sidebar-item">
        <i className="icon-contact tv-left-pannel__sidebar-icon tv-icon-fixed-width" />
        <span><Translate content="sideBar.contactTransvoice" /></span>
      </NavLink>
    </nav>
  );
}
SideBar.propTypes = propTypes;
SideBar.defaultProps = defaultProps;

const mapStateToProps = state => ({
  reports: state.reportDetails.reports,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);

