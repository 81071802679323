import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SideBar, RightStatus } from '../components';
import { LoadingOverlay, Logo } from '../../Share/components';
import history from '../../Share/utils/history';
import {
  onGetUserData,
} from '../actions';

const propTypes = {
  children: PropTypes.element.isRequired,
};

const defaultProps = {
};

class MainLayout extends Component {
  constructor(props) {
    super(props);
    history.listen((location) => {

    });
  }

  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return (
      <React.Fragment>
        <div className="tv-container">
          <div className="tv-display-flex">
            <div className="tv-left-pannel__wrapper">
              <Logo text="Client Portal" />
              <div className="tv-left-content">
                <SideBar />
              </div>
            </div>

            <div className="tv-right-pannel-wrapper">
              <RightStatus key="RightStatus" />
              <div className="tv-right-content">
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
        <LoadingOverlay />
      </React.Fragment>
    );
  }
}

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoad: () => {
    dispatch(onGetUserData());
  },
});

export default connect(null, mapDispatchToProps)(MainLayout);
