import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  isSelected: PropTypes.bool,
  handleClick: PropTypes.func,
};

const defaultProps = {
  className: '',
  text: '',
  isSelected: false,
  handleClick: () => {},
};

const ToggleButton = ({ className, isSelected, handleClick, text }) => {
  const btnClassName = classNames({
    'tv-toggle-button': true,
    [className]: true,
    'tv-toggle-button--unselected': !isSelected,
  });

  return (
    <button className={btnClassName} onClick={handleClick}>
      {text}
    </button>
  );
};

ToggleButton.propTypes = propTypes;
ToggleButton.defaultProps = defaultProps;

export default ToggleButton;
