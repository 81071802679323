import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOverlay, Translate } from '../../Share/components';

const propTypes = {
  children: PropTypes.element.isRequired,
};

const defaultProps = {
};

const LoginLayout = props => (
  <React.Fragment>
    <div className="tv-bg-login-page">
      <div className="tv-container">
        <div className="tv-login-page__wrapper tv-display-flex">
          <div className="tv-login-page__left-wrapper tv-bg-login-client-page tv-display-flex">
            <div className="tv-login-page__left-logo-wrapper ">
              <img className="tv-login-page__left-logo-icon" src="/images/logo.png" alt="" />
              <div className="tv-login-page__left-logo-text"><Translate content="login.reportPortal" /></div>
            </div>

            <div className="tv-login-page__left-footer-wrapper">
              <div className="tv-login-page__left-footer-text">
                <Translate content="login.productOfTransvoice" /> <span>|</span> <Translate content="login.copyRight" />
              </div>
            </div>
          </div>

          <div className="tv-login-page__right tv-login-page__right-wrapper tv-display-flex">
            {props.children}
          </div>
        </div>
      </div>
    </div>
    <LoadingOverlay />
  </React.Fragment>
);

LoginLayout.propTypes = propTypes;
LoginLayout.defaultProps = defaultProps;

export default LoginLayout;
