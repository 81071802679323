import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Avatar from '../Avatar';
import { onUpdateSearchByOrder, getCustomer, logout } from '../../actions';
import './style.scss';
import history from '../../../Share/utils/history';
import { Translate } from '../../../Share/components';

const propTypes = {
  interpreterId: PropTypes.string,
  mapOnUpdateSearchByOrder: PropTypes.func,
  // searchOrder: PropTypes.string,
  profile: PropTypes.shape({}),
  onGetCustomer: PropTypes.func,
  onLogout: PropTypes.func,
};

const defaultProps = {
  interpreterId: '',
  mapOnUpdateSearchByOrder: () => { },
  profile: {},
  // searchOrder: '',
  onGetCustomer: () => {},
  onLogout: () => {},
};

const INITAL_STATES = {
  searchOrder: '',
  isSearchInMenu: false,
};

class RightStatus extends Component {
  // state = INITAL_STATES;
  constructor(props) {
    super(props);
    this.state = INITAL_STATES;
    history.listen((location) => {
      if (location.pathname !== '/search-result') {
        if (this.ismounted) {
          this.setState({
            isSearchInMenu: false,
          });
        }
      }
    });
  }

  componentDidMount() {
    this.ismounted = true;
  }

  componentWillUnmount() {
    this.ismounted = false;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.searchOrder === '' && !prevState.isSearchInMenu) {
      return {
        searchOrder: '',
      };
    }
    return null;
  }

  handleUpdateOrder = (event) => {
    this.setState({
      searchOrder: event.target.value,
      isSearchInMenu: true,
    });
  }

  handleSubmitSearch = () => {
    const { mapOnUpdateSearchByOrder } = this.props;
    this.setState({
      isSearchInMenu: false,
    });
    mapOnUpdateSearchByOrder(this.state.searchOrder);
    history.push('/search-result');
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleSubmitSearch();
    }
  }

  render() {
    const { searchOrder } = this.state;
    return (
      <div className="tv-right-status">
        <div className="tv-user-info">
          <Avatar
            interpreterId={this.props.interpreterId}
            profile={this.props.profile}
            getCustomer={this.props.onGetCustomer}
            logout={this.props.onLogout}
            key ={'avatar'}
          />
        
        </div>

      </div>
    );
  }
}

RightStatus.propTypes = propTypes;
RightStatus.defaultProps = defaultProps;

const mapStateToProps = state => ({
  // interpreterId: state.authentication.interpreterId,
   profile: state.user,
  // searchOrder: state.workAssignment.searchOrder,
});

const mapDispatchToProps = dispatch => ({
  mapOnUpdateSearchByOrder: (searchOrder) => {
    //dispatch(onUpdateSearchByOrder(searchOrder));
  },
  mapOnResetMyAssignment: () => {
    // dispatch(onResetMyAssignment());
  },
  onGetCustomer: () => {
    //dispatch(getCustomer());
  },
  onLogout: () => {
    dispatch(logout());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RightStatus);
