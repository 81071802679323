import { toast } from 'react-toastify';
import history from '../../Share/utils/history';
import * as api from '../api';
import { showLoadingOverlay, hiddenLoadingOverlay } from './app';
import { broadCast } from './eventBus';
import {
  transvoiceLog,
  redirectToDashBoard,
  logApiLevel,
} from '../../Share/utils';
import { Translate } from '../../Share/components';

import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESET_APP,
  AUTHENTICATE_RESOURCE_SUCCESS,
  GET_USER_DATA,
  SET_USER_AGREEMENT,
} from './ActionTypes';
import { LOCAL_STORE_USER_DATA } from '../../Share/constants';
import generateHashPassword from '../../Share/utils/hashUtil';

export function logout() {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_APP,
    });
  };
}

async function CheckToken(customerName, passwordHash) {
  try {
    const authResponse = await api.validateCustomerPassword(customerName, passwordHash);
    const accessToken = authResponse.data.apiKey;
    const e = new Error(Translate({ content: 'error.tokenErrorReportPortal' }));
    e.name = 'LoginError';
    if (!accessToken || accessToken === '') throw e;
    return { accessToken, userProfile: authResponse.data.userProfile };
  } catch (error) {
    const e = new Error(Translate({ content: 'error.tokenErrorReportPortal' }));
    e.name = 'LoginError';
    throw e;
  }
}

export function login(customerName, password) {
  return async (dispatch, getState) => {
    try {
      dispatch(showLoadingOverlay());
      const passwordHash = generateHashPassword(password);
      const { accessToken, userProfile } = await CheckToken(customerName, passwordHash);
      userProfile.agreements = [{ id: 0, name: Translate({ content: 'report.all' }) }, ...userProfile.agreements];

      dispatch({
        type: AUTHENTICATE_RESOURCE_SUCCESS,
        customerName,
      });
      dispatch({
        type: GET_USER_DATA,
        userData: userProfile,
      });
      localStorage.setItem(LOCAL_STORE_USER_DATA, JSON.stringify(userProfile));
      localStorage.setItem('SELECTED_AGREEMENT', JSON.stringify(userProfile.agreements[0]));
      dispatch({
        type: SET_USER_AGREEMENT,
        selectedAgreement: userProfile.agreements[0],
      });
      dispatch({
        type: LOGIN_SUCCESS,
        apiKey: accessToken,
        customerName,
        password,
      });
      redirectToDashBoard();
    } catch (error) {
      transvoiceLog(error, logApiLevel.error);
      if (error.name === 'LoginError') toast.error(error.message);
      dispatch({ type: LOGIN_FAILURE, error });
      dispatch(hiddenLoadingOverlay());
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function createToken(customerName, passwordHash) {
  return async (dispatch, getState) => {
    try {
      const accessToken = await CheckToken(customerName, passwordHash);

      dispatch({
        type: LOGIN_SUCCESS,
        token: accessToken,
        customerName,
        passwordHash,
      });

      broadCast('newTokenCreated', [dispatch, {}]);
      redirectToDashBoard();
    } catch (error) {
      transvoiceLog(error, logApiLevel.error);
      if (error.name === 'LoginError') toast.error(error.message);
      dispatch({ type: LOGIN_FAILURE, error });
      history.push('/login');
    }
  };
}

// export const autoCreateNewToken = () => (dispatch, getState) => Promise.resolve().then(async () => {
//   const state = getState();
//   const { customerName, passwordHash } = state.authentication;

//   if (customerName && passwordHash) {
//     transvoiceLog(`autoCreateNewToken ${customerName}`);
//     try {
//       const accessToken = await CheckToken(customerName, passwordHash);

//       dispatch({
//         type: LOGIN_SUCCESS,
//         token: accessToken,
//         customerName,
//         passwordHash,
//       });

//       broadCast('newTokenCreated', [dispatch, {}]);
//       redirectToDashBoard();
//     } catch (error) {
//       transvoiceLog(error, logApiLevel.error);
//       if (error.name === 'LoginError') toast.error(error.message);
//       dispatch({ type: LOGIN_FAILURE, error });
//       history.push('/login');
//     }
//   } else {
//     dispatch(logout());
//     history.push('/login');
//   }
// });

// export function resetPassword(plainTextPassword) {
//   return async (dispatch, getState) => {
//     try {
//       dispatch(showLoadingOverlay());
//       const state = getState();
//       const { token } = state.authentication;

//       const hash = generateHashPassword(plainTextPassword);
//       await api.setCustomerPassword(token, hash);

//       history.push('/'); // Go to dashboard
//       showToastMessage('success', Translate({ content: 'resetPassword.resetSuccessfull' }));
//     } catch (error) {
//       transvoiceLog(error, logApiLevel.error);
//     } finally {
//       dispatch(hiddenLoadingOverlay());
//     }
//   };
// }

// export function setNewPassword(customerName, plainTextPassword) {
//   return async (dispatch, getState) => {
//     try {
//       dispatch(showLoadingOverlay());

//       let accessToken = null;
//       try {
//         accessToken = await CheckToken(customerName, null);
//       } catch (error) {
//         throw error;
//       }

//       const hash = generateHashPassword(plainTextPassword);
//       await api.setCustomerPassword(accessToken, hash);

//       history.push('/'); // Go to dashboard
//       showToastMessage('success', Translate({ content: 'resetPassword.setSuccessful' }));
//     } catch (error) {
//       // if (error.name === 'LoginError') toast.error(error.message);
//       if (error.name === 'LoginError') showToastMessage('error', Translate({ content: 'resetPassword.setFailed' }));
//       transvoiceLog(error, logApiLevel.error);
//     } finally {
//       dispatch(hiddenLoadingOverlay());
//     }
//   };
// }


// export function validateCurrentPassword(password, callback) {
//   return async (dispatch, getState) => {
//     try {
//       dispatch(showLoadingOverlay());

//       const { customerName } = getState().authentication;

//       const passwordHash = generateHashPassword(password);
//       await CheckToken(customerName, passwordHash);
//       // When validate password successfully
//       if (callback) {
//         callback();
//       }
//     } catch (error) {
//       if (error.name === 'LoginError') toast.error(error.message);
//       transvoiceLog(error);
//     } finally {
//       dispatch(hiddenLoadingOverlay());
//     }
//   };
// }
