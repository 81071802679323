import axios from 'axios';

const apiLoggerInstance = axios.create({
  baseURL: process.env.REACT_APP_LOG_API,
});

export const logApiLevel = {
  debug: 0,
  info: 1,
  warn: 2,
  error: 3,
  fatal: 4,
};

const statusCodeLog = [400, 401, 403, 404, 500];

export function logApi(message, level = logApiLevel.error) {
  const messageBuilder = `[${process.env.REACT_APP_ENV}][${process.env.REACT_APP_PORTAL}]:
   ${message.response.status}-${message.response.statusText}-${message.response.data}`;

  if (process.env.REACT_APP_LOG_API) {
    apiLoggerInstance.post('/SendLog', {
      Message: messageBuilder,
      level,
    }).catch(error => console.error(`apiLogError: ${error}`));
  }
}

export function serviceLog(message, type = logApiLevel.error) {
  // send error message to server
  if (type === logApiLevel.error
    && message.response
    && message.response.status
    && statusCodeLog.indexOf(message.response.status) > -1) {
    logApi(message, type);
  }
}
