import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const propTypes = {
  handleSelectRate: PropTypes.func,
  className: PropTypes.string,
  rate: PropTypes.number,
  isDisabled: PropTypes.bool,
};

const defaultProps = {
  handleSelectRate: () => { },
  className: '',
  rate: 0,
  isDisabled: false
};

const votes = [5, 4, 3, 2, 1];

const Rating = ({ handleSelectRate, className, rate, isDisabled }) => (
  <div className={`rating ${className}`}>
    {votes.map((item, i) => (
      <React.Fragment key={i.toString()}>
        <input defaultChecked={item === rate ? true : false} disabled={isDisabled} onClick={() => handleSelectRate(item)} type="radio" id={`star${item}`} name="rating" value={item} />
        <label  className="full" htmlFor={`star${item}`}></label>
      </React.Fragment>
    ))}
  </div>
);

Rating.propTypes = propTypes;
Rating.defaultProps = defaultProps;

export default Rating;
