import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { preventScrollWhenVisible, removeContextualClass } from '../../utils';

import IfComponent from '../IfComponent';
import CloseButton from '../CloseButton';

const propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onCloseClicked: PropTypes.func,
  onOverlayClick: PropTypes.func,
};

const defaultProps = {
  isVisible: false,
  children: null,
  className: '',
  onCloseClicked: () => { },
  onOverlayClick: () => { },
};

const INITAL_STATES = {
};

class DrawerSlide extends Component {
  state = INITAL_STATES;

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isVisible } = nextProps;
    if (isVisible) {
      preventScrollWhenVisible(isVisible);
    } else {
      removeContextualClass();
    }
    return null;
  }

  componentDidMount(nextProps) {
    const { isVisible } = this.props;
    preventScrollWhenVisible(isVisible);
  }

  onHandlerClose = (e, fn) => {
    removeContextualClass();
    if(fn){
        setTimeout(() => {
        fn(e);
        }, 550);
    }
  }

  render() {
    const { className, children, isVisible, onCloseClicked, onOverlayClick } = this.props;
    return (
      <IfComponent
        condition={isVisible}
        whenTrue={(
          <div
            className={`tv-workassignment__drawer-wrapper ${className}`}
          >
            <div
              className="tv-workassignment__drawer-overlay"
              onClick={(e) => { this.onHandlerClose(e, onOverlayClick); }}
              role="button"
              tabIndex="-1"
            />
            <div className="tv-workassignment__drawer-holder">
              <CloseButton
                onClick={(e) => { this.onHandlerClose(e, onCloseClicked); }}
                className="tv-workassignment__drawer-close tv-buttons__type icon-close"
              />
              <div>
                {(children)}
              </div>
            </div>
          </div>
        )}
        whenFalse={null}
      />
    );
  }
}

DrawerSlide.propTypes = propTypes;
DrawerSlide.defaultProps = defaultProps;


export default DrawerSlide;