import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import '@progress/kendo-theme-material/dist/all.css';

import moment from 'moment';
import 'hammerjs';
import {
  Dashboard,
  Login,
  Logout,
  Setting,
  ContactTransvoice,
  LandingPage,
} from './components';
import '../Share/styles/index.scss';


import { MainLayout, LoginLayout, BlankLayout } from './layouts';
import { PrivateRoute, PublicRoute, ReportRoute } from './router';
import history from '../Share/utils/history';
import { NotFound, MobileApp, Version, TermAndCondition, PrivacyPolicy, Translate } from '../Share/components/';
import { LOCAL_STORE_LANGUAGE } from '../Share/constants/localStorage';
let lang = localStorage.getItem(LOCAL_STORE_LANGUAGE);
switch (lang) {
  case 'en':
    lang = 'en';
    break;
  case 'se':
    lang = 'sv-SE';
    break;
  default:
    lang = 'sv-SE';
    break;
}

moment.locale(lang, {
  week: {
    dow: 1, // Monday is the first day of the week
  },
});

class Root extends React.Component {
  componentDidMount() {
    document.title = Translate({ content: 'login.reportPortal' });
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <PrivateRoute exact path="/" layout={MainLayout} component={Dashboard} />
          <PublicRoute exact path="/logout" layout={LoginLayout} component={Logout} />
          <PublicRoute exact path="/login" layout={LoginLayout} component={Login} />
          <ReportRoute exact path="/report/:id" layout={MainLayout} />
          <PrivateRoute exact path="/settings" layout={MainLayout} component={Setting} />
          <PrivateRoute exact path="/contact-transvoice" layout={MainLayout} component={ContactTransvoice} />
          <PrivateRoute layout={MainLayout} component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default Root;
