import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

const defaultProps = {
  children: null,
  className: 'tv-filter-list__menu',
};

const FilterMenu = ({ children, className }) => (
  <ul className={className}>{(children)} </ul>
);

FilterMenu.propTypes = propTypes;
FilterMenu.defaultProps = defaultProps;

export default FilterMenu;
