import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ValidatorForm } from 'react-form-validator-core';
import 'react-tabs/style/react-tabs.css';
import './style.scss';
import { TransvoiceButton, TextField, Translate, TermAndCondition, PrivacyPolicy } from '../../../Share/components';
import history from '../../../Share/utils/history';
import { TRANSVOICE_EMAIL_INFO } from '../../../Share/constants';

const TAB_CONTACT_TRANSVOICE = 2;

class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      password: '',
    };
  }

  handleToggleSwitchChange = () => {
    const { isChecked } = this.state;
    this.setState({
      isChecked: !isChecked,
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const redirectToResetPasswordPage = () => {
      // Go to /reset-password
      history.push({
        pathname: '/reset-password',
        state: {
          from: '/settings',
        },
      });
    };

    this.props.onValidateCurrentPassword(this.state.password, redirectToResetPasswordPage);
  }

  handleMailClick = (e) => {
    e.preventDefault();
    window.location.href = `mailto:${TRANSVOICE_EMAIL_INFO}`;
  }

  handleDefaultTabDisplay = () => {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    let defaultTabIndex = 0;

    const redirectFromNavBarContactTransvoice = (from.pathname === '/contact-transvoice');
    if (redirectFromNavBarContactTransvoice) {
      defaultTabIndex = 0;
    }

    return defaultTabIndex;
  }

  render() {
    const defaultTabIndex = this.handleDefaultTabDisplay();

    return (
      <div className="tv-settings__wrapper">
        <h3 className="tv-settings__title"><Translate content="settings.title" /></h3>

        <Tabs className="tv-settings__tabs-wrapper" defaultIndex={defaultTabIndex}>
          <TabList>      
            <Tab>
              <div className="icon-comment" />
              <div>
                <div className="tv-settings__tab-text-main"><Translate content="settings.contactTransvoice" /></div>
                <div className="tv-settings__tab-text-sub"><Translate content="settings.contactToTransvoice" /></div>
              </div>
            </Tab>
            <Tab>
              <div className="icon-drawing-pin" />
              <div className="tv-settings__tab-text-main">
                T&C <br />
                <Translate content="settings.termAndPolicy" />
              </div>
            </Tab>
          </TabList>
          <TabPanel>
            <div className="tv-settings__panel-title"><Translate content="settings.contactTransvoice" /></div>
            <div className="tv-settings__contact-text">
              Stockholm <br />
              Sveavägen 159 plan 3 <br />
              113 46 Stockholm <br />
              08-120 800 00 <br />
            </div>
            <div className="tv-settings__contact-text">
              Norrköping <br />
              Olai Kyrkogata 40 <br />
              602 33 Norrköping <br />
              011-12 21 30 <br />
            </div>
            <div className="tv-settings__contact-text">
              Malmö <br />
              Grynbodgatan 14 <br />
              211 33 Malmö <br />
              040-23 02 05 <br />
            </div>
            <TransvoiceButton
              type="line-primary"
              text={Translate({ content: 'settings.sendTransvoiceAnEmail' })}
              className="tv-settings__contact-btn"
              onClick={this.handleMailClick}
            />
          </TabPanel>
          <TabPanel>

          <Tabs className="react-tabs--vertical">
              <TabList>
                <Tab><Translate content="settings.termAndConditions" /></Tab>
                <Tab><Translate content="settings.termAndPolicy" /></Tab>
              </TabList>
              <TabPanel>
                <TermAndCondition />
              </TabPanel>
              <TabPanel>
                <PrivacyPolicy />
              </TabPanel>
            </Tabs>

          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onValidateCurrentPassword: (plainTextPassword, callback) => {
    // dispatch(validateCurrentPassword(plainTextPassword, callback));
  },
});

export default connect(null, mapDispatchToProps)(Setting);
