import crypto from 'crypto';

const SALT = 'transvoiceweb@12345678';

const sha512 = (password, salt) => {
  const hash = crypto.createHmac('sha512', salt); /** Hashing algorithm sha512 */
  hash.update(password);
  const value = hash.digest('hex');
  return {
    salt,
    passwordHash: value,
  };
};

export default function generateHashPassword(plainTextPassword) {
  const passwordData = sha512(plainTextPassword, SALT);
  return passwordData.passwordHash;
}

