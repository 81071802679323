import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const propTypes = {
	isChecked: PropTypes.bool,
	onToggleSwitchChange: PropTypes.func,
};

const defaultProps = {
	isChecked: false,
	onToggleSwitchChange: () => { },
};

class ToggleSwitch extends React.Component {

	render() {
		const { isChecked, onToggleSwitchChange } = this.props;
		return (
			<div className='tv-toggleSwitch tv-toggleSwitch__rounded'>
				<div className='tv-toggleSwitch__wrapper'>
					<div tabIndex="-1" className={`Slider ${isChecked && 'isChecked'}`} onClick={onToggleSwitchChange}></div>
				</div>
			</div>
		);
	}
}

ToggleSwitch.propTypes = propTypes;
ToggleSwitch.defaultProps = defaultProps;

export default ToggleSwitch;
