import React from 'react';
import PropTypes from 'prop-types';
import { ThreeDotLoader } from '../../../Share/components';

function PercentageBoxWidget(props) {
  const assignColor = value => (value < 1 ? 'black' : value < 80 ? 'red' : value < 95 ? '#ffc700' : 'green');
  return (
    <div className="dashboard__display-card round-border">

      <div className="auto-row-2">
        <div style={{
          color: assignColor(props.value), fontSize: '2.5rem', textAlign: 'center', padding: '5px',
        }}
        >{props.dataReadyFlag ? `${props.value.toLocaleString('sv-Se', { maximumFractionDigits: 1 })}%` : <ThreeDotLoader />}
        </div>
        <div style={{ textAlign: 'center', padding: '5px' }}>{props.title}</div>

      </div>
    </div>
  );
}

PercentageBoxWidget.propTypes = {

};

export default PercentageBoxWidget;

