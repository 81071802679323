import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    text: '',
    onClick: () => { },
    className: 'tv-filter-list__button tv-buttons__type',
};

const FilterButton = ({ text, onClick, className }) => (
    <button onClick={onClick} className={className}
        dangerouslySetInnerHTML={{
            __html: text,
        }} />
);

FilterButton.propTypes = propTypes;
FilterButton.defaultProps = defaultProps;

export default FilterButton;