import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import {
  ReportContainer,
} from '../components/Report';
import { NotFound } from '../../Share/components';

const propTypes = {
  layout: PropTypes.elementType.isRequired,
};

const defaultProps = {
};

const ReportRoute = ({ layout: Layout, ...rest }) => {
  const param = parseInt(rest.computedMatch.params.id, 10);
  return (param ?
    <PrivateRoute
      exact
      path={rest.path}
      layout={Layout}
      component={
        ReportContainer}
    /> : <PublicRoute layout={Layout} component={NotFound} />
  );
};
ReportRoute.propTypes = propTypes;
ReportRoute.defaultProps = defaultProps;

export default ReportRoute;
