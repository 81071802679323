import { toast } from 'react-toastify';
import axios from 'axios';
import { transvoiceLog } from '../../Share/utils';

import { Translate } from '../../Share/components';

export const responseInterceptor = async response => response;

export const errorInterceptor = (error) => {
  toast.error(Translate({ content: 'error.commonError' }));
  transvoiceLog('error interceptor');
  return Promise.reject(error);
};
