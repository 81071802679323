import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeDatasourceCrossFilter } from '../../actions';
import { Translate } from '../../../Share/components';


const propTypes = {
  dashboardFilter: PropTypes.shape({}),
  onRemoveDashboardChartFilter: PropTypes.func,
};
const defaultProps = {
  dashboardFilter: {},
  onRemoveDashboardChartFilter: () => {},
};

const DataSourceFilter = props => (props.dashboardFilter.key ?
  <div className="float-filter">
    <div className="float-filter-icon-box">
      <span className="k-icon k-i-filter" />
    </div>
    <div className="float-filter-content auto-col-2">
      <span>{`${Translate({ content: `report.${props.dashboardFilter.key}` })} : ${props.dashboardFilter.value}`}</span>
      <span
        className="k-icon k-i-close-outline float-filter-clear-btn"
        onClick={(e) => { e.stopPropagation(); props.onRemoveDashboardChartFilter(); }}
      />
    </div>

  </div> : null
);

DataSourceFilter.propTypes = propTypes;
DataSourceFilter.defaultProps = defaultProps;

const mapStateToProps = (state, props) => ({
  dashboardFilter: state.app.dashboardFilter,
});

const mapDispatchToProps = dispatch => ({
  onRemoveDashboardChartFilter: () => {
    dispatch(removeDatasourceCrossFilter());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DataSourceFilter);
