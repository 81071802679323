import React from 'react';
import './style.scss';
import { TransvoiceButton } from '..';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
  buttonText: PropTypes.string,
  callbackUrl: PropTypes.string,
};

const defaultProps = {
  children: null,
  buttonText: '',
  callbackUrl: '',
};


const ThankYouComponent = ({ children, buttonText, callbackUrl }) =>
  (
    <div className="thanks-wrapper">
      <div className="thanks-wrapper__header" />
      <div className="thanks-wrapper__content">
        {(children)}
      </div>
      <div className="thanks-wrapper__footer">
        <NavLink to={callbackUrl}>
          <TransvoiceButton type="primary" text={buttonText} />
        </NavLink>
      </div>
    </div>
  );

ThankYouComponent.propTypes = propTypes;
ThankYouComponent.defaultProps = defaultProps;

export default ThankYouComponent;
