export const RESET_APP = 'RESET_APP';

export const TOGGLE_LOADING_OVERLAY = 'TOGGLE_LOADING_OVERLAY';

export const AUTHENTICATE_RESOURCE_SUCCESS = 'AUTHENTICATE_RESOURCE_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const GET_USER_DATA = 'GET_USER_DATA';
export const SET_USER_AGREEMENT = 'SET_USER_AGREEMENT';
export const SET_DATASOURCE = 'SET_DATASOURCE';
export const SET_REPORT_DATASOURCE = 'SET_REPORT_DATASOURCE';
export const SET_REPORT_DATASOURCE_RAW = 'SET_REPORT_DATASOURCE_RAW';
export const PUSH_DATASOURCE_TO_QUEUE = 'PUSH_DATASOURCE_TO_QUEUE';
export const PROCESS_DATASOURCE_QUEUE = 'PROCESS_DATASOURCE_QUEUE';
export const POP_DATASOURCE_TO_QUEUE = 'POP_DATASOURCE_TO_QUEUE';
export const GET_REPORTDATA = 'GET_REPORTDATA';

export const SET_CROSS_FILTER = 'SET_CROSS_FILTER';
export const REMOVE_CROSS_FILTER = 'REMOVE_CROSS_FILTER';

export const UPDATE_REPORT_DATE_PERIOD = 'UPDATE_REPORT_DATE_PERIOD';
export const UPDATE_REPORT_TABLES = 'UPDATE_REPORT_TABLES';
export const GET_MONTHLY_INTERPRETATIONS = 'GET_MONTHLY_INTERPRETATIONS';
export const GET_LATE_CANCELED_ASSIGNMENT = 'GET_LATE_CANCELED_ASSIGNMENT';
export const GET_TRAVEL_TIME_ALLOWANCES = 'GET_TRAVEL_TIME_ALLOWANCES';
export const GET_EMERGENCY_PREBOOKED_ASSIGNMENTS = 'GET_EMERGENCY_PREBOOKED_ASSIGNMENTS';
export const GET_ASSIGNMENT_BY_UNITS = 'GET_ASSIGNMENT_BY_UNITS';
export const SET_DASHBOARD_DATERANGE = 'SET_DASHBOARD_DATERANGE';

