import React, { useEffect, useState } from 'react';
import { RadialGauge } from '@progress/kendo-react-gauges';


const GaugeWidget = (props) => {
  const [displaValue, setDisplaValue] = useState(0);
  useEffect(() => {
    setDisplaValue(props.value);
  }, [props.value]);
  return (

    <React.Fragment>
      <div style={{ display: 'flex' }} className="tv-radialgauge-container">
        <RadialGauge
          style={{ display: 'flex', margin: '10px' }}
          shape="arrow"
          renderAs="svg"
          scale={{
            labels: { format: 'p' },
            minorUnit: 0.05,
            majorUnit: 0.2,
            max: 1,
            ranges: [
              { from: 0.8, to: 1, color: '#ffc700' },
            ],
          }}
          pointer={{value:displaValue}}
        />
        <div className="tv-radialgauge-inner_detail">
          <span>{`${(displaValue || 0).toLocaleString('sv-Se', { style: 'percent', maximumFractionDigits: 1 })}`}</span>
          <span>{props.title}</span>
        </div>
      </div>
    </React.Fragment>

  );
};


export default GaugeWidget;
