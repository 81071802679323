import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  className: '',
  title: '',
  children: null,
};

const BlockWrapper = ({ className, children, title }) => (
  <div className='tv-block-wrapper'>
    <div className={`${className} tv-block-wrapper__title`}>
      {title}
    </div>
    {children}
  </div>
);

BlockWrapper.propTypes = propTypes;
BlockWrapper.defaultProps = defaultProps;

export default BlockWrapper;
