import { Translate } from '../components';

export const APP_PORTAL = process.env.REACT_APP_PORTAL;
export const ENV_CLIENT = 'client-portal';
export const ENV_INTERPRETER = 'interpreter-portal';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const GLOBAL_DATE_FORMAT = 'D MMM YYYY';
export const timezoneBE = 'Europe/Stockholm';

export const resourceRequestStatus = {
  UNSENT: 1,
  SENT: 2,
  ACCEPTED: 3,
  CANCELLED: 4,
  DECLINED: 5,
  EXPIRED: 6,
};

export const availabilityStatus = {
  AVAILABLE: 1,
  AVAILABLE_WITHOUT_INQUIRY: 2,
  UNAVAILABLE: 3,
};

export const status = {
  available: {
    name: 'available',
    titleButtonCancel: Translate({ content: 'status.reject' }),
    titleButtonCancelClient: Translate({ content: 'status.cancel' }),
    titleButtonSubmit: Translate({ content: 'status.accept' }),
    titleButtonCancelOnSite: Translate({ content: 'status.reject' }),
    titleButtonSubmitOnSite: Translate({ content: 'status.accept' }),
  },
  accepted: {
    name: 'accepted',
    titleButtonCancel: Translate({ content: 'timesheet.cantCompleted' }),
    titleButtonCancelOnSite: Translate({ content: 'timesheet.cantCompleted' }),
    titleButtonCancelClient: Translate({ content: 'status.cancel' }),
    titleButtonSubmit: Translate({ content: 'status.startWorking' }),
    titleButtonSubmitOnSite: Translate({ content: 'status.timeReport' }),
  },
  working: {
    name: 'working',
    titleButtonCancel: Translate({ content: 'timesheet.cantCompleted' }),
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: Translate({ content: 'general.done' }),
    titleButtonSubmitOnSite: Translate({ content: 'status.timeReport' }),
  },
  fullfilled: {
    name: 'fullfilled',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: Translate({ content: 'status.timeReport' }),
    titleButtonSubmitOnSite: Translate({ content: 'status.timeReport' }),
  },
  submitted: {
    name: 'submitted',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: Translate({ content: 'status.timeReport' }),
    titleButtonSubmitOnSite: Translate({ content: 'status.timeReport' }),
  },
  done: {
    name: 'done',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: Translate({ content: 'status.timeReport' }),
    titleButtonSubmitOnSite: Translate({ content: 'status.timeReport' }),
  },
  cancelled: {
    name: 'cancelled',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: '',
    titleButtonSubmitOnSite: '',
  },
  rejected: {
    name: 'rejected',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: '',
    titleButtonSubmitOnSite: '',
  },
  losted: {
    name: 'losted',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: '',
    titleButtonSubmitOnSite: '',
  },
  getByStatusString: (strStatus) => {
    if (strStatus === 'available') return status.available;
    if (strStatus === 'accepted') return status.accepted;
    if (strStatus === 'working') return status.working;
    if (strStatus === 'fullfilled') return status.fullfilled;
    if (strStatus === 'submitted') return status.submitted;
    if (strStatus === 'done') return status.done;
    if (strStatus === 'cancelled') return status.cancelled;
    if (strStatus === 'rejected') return status.rejected;
    if (strStatus === 'losted') return status.losted;

    return status.available;
  },
};

export const statusMapping = {
  Inkommen: status.available.name,
  Förlorad: status.losted.name,
  Accept: status.accepted.name,
  Avbeställd: status.cancelled.name,
  Återbud: status.cancelled.name,
  Avböjd: status.rejected.name,
  'Avslutad/klar': status.submitted.name,
};

export const breakTimes = [
  { value: 0, name: '0 min' },
  { value: 2, name: '2 min' },
  { value: 5, name: '5 min' },
  { value: 10, name: '10 min' },
  { value: 20, name: '20 min' },
  { value: 30, name: '30 min' },
  { value: 40, name: '40 min' },
  { value: 50, name: '50 min' },
  { value: 60, name: '60 min' },
];

export const LOCAL_STORE_TOKEN = 'token';
export const LOCAL_STORE_APIKEY = 'apiKey';
export const LOCAL_STORE_USER_DATA = 'USER_DATA';
export const LOCAL_STORE_EMAIL = 'email';
export const LOCAL_STORE_INTERPRETER_ID = 'interpreterId';
export const LOCAL_STORE_RESOURCE_IDENTIFIER = 'resourceIdentifier';
export const LOCAL_STORE_LOCATION_PHONE_ID = 'locationPhoneId';
export const LOCAL_STORE_LOCATION_ONSITE_ID = 'locationOnSiteId';
export const LOCAL_STORE_IS_SYNC_WITH_GOOGLE_CALENDAR = 'isSyncWithGoogleCalendar';
export const LOCAL_STORE_ASSIGNMENT_LIST = 'assignmentList';
export const LOCAL_STORE_SKILLS = 'skills';
export const LOCAL_STORE_CONTACT_PERSONS = 'contactPersons';
export const LOCAL_STORE_CUSTOMER_NUMBER = 'customerNumber';
export const LOCAL_STORE_CUSTOMER_NAME = 'customerName';
export const LOCAL_STORE_CUSTOMER_PASSWORD = 'customerPassword';
export const LOCAL_STORE_SERVICES = 'services';
export const LOCAL_STORE_REASONS = 'reasons';
export const LOCAL_STORE_COMPLAINT_REASONS = 'complaintReasons';

export const timeJob = {
  JOB_TIMER_MY_ASSIGNMENT: 30,
  JOB_TIMER_ASSIGNMENT_REQUEST: 30,
};

export const DEFAULT_EMPTY_NAME = '-';
export const DEFAULT_EMPTY_VALUE = 'none';

export const SINGLE_FORM = 1;
export const MULTI_FORM = 2;

export const acceptedFileType = {
  'application/msword': 'doc',
  'application/pdf': 'pdf',
  'image/jpg': 'image',
  'image/jpeg': 'image',
  'image/png': 'image',
  'image/bmp': 'image',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
};

export const errorCode = {
  invalidApiKey: 1001,
  invalidResourceNumber: 1002,
  invalidToken: 1003,
  invalidCustomerNumber: 1004,

  mandatoryFieldMissing: 2001,
  stringLengthExceedsLimit: 2002,
  invalidDateTimeString: 2003,
  unexpectedDataType: 2004,
  invalidBase64String: 2005,
  invalidEmailFormat: 2006,
  invalidPhoneNumber: 2007,
  invalidPostalCode: 2008,

  workAssignmentNotFound: 3001,
  resourceRequestNotFound: 3002,
  attachmentNotFound: 3003,
  resourceRequestExpire: 3004,
  resourceRequestAlreadyAccepted: 3005,
  resourceRequestAlreadyDeclined: 3006,
  unableToUpdateTimeReport: 3007,
  articleNotFound: 3008,
  invalidAvailability: 3009,
  unableToCancelWorkAssignment: 3010,
  unableToUpdateExpenses: 3011,
  workAssignmentAlreadyCancelled: 3012,
  fileNotFound: 3013,
  invalidResourceType: 3014,
  workAssignmentAlreadyComplete: 3015,
  unableToCompleteWorkAssingmentWithAnomalyType: 3016,
  invalidCalendarSyncSourceType: 3017,

  CrmUnresponsive: 4001,
  billingUnresponsive: 4002,

  orderNotFound: 5001,
  unableToCancelOrder: 5100,
  orderAlreadyCancelled: 5101,
  cancellationReasonNotFound: 5102,
  postalCodeNotConnectedToCounty: 5200,
  invalidService: 5201,
  invalidSkill: 5202,
  invalidPhoneFormat: 5203,
  missingContactPersonOrderer: 5204,
  missingContactPersonUponInterpretation: 5205,
  mandatoryContactPersonOrdererFieldsMissing: 5206,
  mandatoryContactPersonUponInterpretationFieldsMissing: 5207,
  missingAttachmentInformation: 5208,
  missingOrderGroupingFields: 5209,
  missingOrdererPhoneNumber: 5210,
  missingContactPersonUponInterpretationPhone: 5211,
  missingOrderOccasion: 5212,

  unhandledCriticalError: 9999,
};

export const COMPENSATION_FILETYPE = 'Lönespecifikation';
export const COMPENSATION_FILETYPE_2 = 'Uppdragsspecifikation';
export const COMPENSATION_FILETYPE_3 = 'Självfaktura';
export const COMPENSATION_DICTIONARIES_FILETYPE = 'Ordlista';
export const COMPENSATION_DICTIONARIES_FILETYPE_2 = 'Ordlistor';
export const COMPENSATION_FEESLIST_FILETYPE = 'Arvodeslista';
export const COMPENSATION_TIMEGAP = -1; // year
export const COMPENSATION_FILE_CLASSNAME = 'tv-file__details-text';

export const TRANSVOICE_EMAIL_INFO = 'info@transvoice.se';
export const TRANSVOICE_EMAIL_REPORT = 'kvalitet@transvoice.se';

export const DIALOG_GOOGLE_WIDTH = 387;
export const DIALOG_GOOGLE_HEIGHT = 150;

export const NUMBER_ROW_OF_MONTHLY_CALENDAR = 6;
export const NUMBER_COL_OF_MONTHLY_CALENDAR = 7; // from sun, mon, ... sat
export const TOTAL_DAY_OF_MONTHLY_CALENDAR = NUMBER_ROW_OF_MONTHLY_CALENDAR * NUMBER_COL_OF_MONTHLY_CALENDAR;

export const TIME_RANGE_GET_DATA = TOTAL_DAY_OF_MONTHLY_CALENDAR;
