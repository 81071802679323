import moment from 'moment';

import { SET_DASHBOARD_DATERANGE } from './ActionTypes';


export function ChangeDashboardDatePeriod(datePeriod) {
  return (dispatch, getState) => {
    const { start, end } = datePeriod;
    const state = getState();

    const newRange = {
      from: start,
      to: end,
    };
    dispatch({
      type: SET_DASHBOARD_DATERANGE,
      range: newRange,
    });
  };
}
