import React from 'react';
import './style.scss';

const NotFound = () => (
  <div className="tv-not-found-page">
    <div className="tv-not-found-page__title">
      Oops! Sorry, the page not found
    </div>
    <div className="tv-not-found__image" />
  </div>
);

export default NotFound;
