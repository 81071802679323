import * as actionTypes from '../actions/ActionTypes';
import { Translate } from '../../Share/components';

const INITIAL_STATE = JSON.parse(localStorage.getItem('USER_DATA')) || {
  name: '',
  agreements: [{ id: 4 }, { id: 22 }, { id: 46 }, { id: 62 }, { id: 64 }, { id: 121 }],
};

const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      return INITIAL_STATE;
    case actionTypes.GET_USER_DATA:
    {
      const { userData } = action;
      return {
        ...userData,
      };
    }
    default:
      return state;
  }
};

export default user;
