import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { truncateFileName } from '../../utils/fileUtil';
import { acceptedFileType } from '../../constants/index';

const propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  fileName: PropTypes.string,
  attachmentId: PropTypes.string,
  onGetAttachment: PropTypes.func,
};

const defaultProps = {
  className: '',
  type: '',
  fileName: '',
  attachmentId: '',
  onGetAttachment: () => {},
};

class Attachment extends Component {
  handleDownload = () => {
    const { attachmentId } = this.props;
    this.props.onGetAttachment(attachmentId);
  }

  shorterFileName = (fileName) => {
    const EXPECT_LENGTH = 10;
    return truncateFileName(fileName, EXPECT_LENGTH, '...');
  }

  render() {
    const { className, type, fileName } = this.props;
    const fileTypeLowerCase = type ? type.toLowerCase() : type;
    const extension = acceptedFileType[fileTypeLowerCase];

    return (
      <button
        className={`tv-attachment ${className}`}
        onClick={this.handleDownload}
      >
        <div className={`tv-attachment__header tv-attachment__header--${extension || 'other'}`} />
        <div className="tv-attachment__details">{this.shorterFileName(fileName)}</div>
      </button>
    );
  }
}

Attachment.propTypes = propTypes;
Attachment.defaultProps = defaultProps;

export default Attachment;
