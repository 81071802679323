// nameWithExt: this-is-a-file-name.jpg
// getFileExt(nameWithExt) --> jpg
export function getFileExt(nameWithExt) {
  return nameWithExt.split('.').pop();
}

// nameWithExt: this-is-a-file-name.jpg
// getFileNameWithoutExt(nameWithExt) --> this-is-a-file-name
export function getFileNameWithoutExt(nameWithExt) {
  return nameWithExt.split('.').slice(0, -1).join('.');
}

// nameWithExt: this-is-a-very-very-very-long-file-name.jpg
// truncate(nameWithExt, 100) --> this-is-a-very-very-very-long-file-name.jpg
// truncate(nameWithExt, 10) --> this-is-a-[...].jpg
// truncate(s, 4) --> this[...].jpg
export function truncateFileName(nameWithExt, expectLength, signal = '[...]') {
  const ext = nameWithExt.substring(nameWithExt.lastIndexOf('.') + 1, nameWithExt.length).toLowerCase();
  let filename = nameWithExt.replace(`. + ${ext}`, '');
  if (filename.length <= expectLength) {
    return nameWithExt;
  }

  filename = filename.substr(0, expectLength) + (nameWithExt.length > expectLength ? signal : '');
  return `${filename}.${ext}`;
}

export function getFileBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

