import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { DropdownMenu, Translate } from '../../../Share/components';
import { isIE } from '../../../Share/utils';

export default class Avatar extends Component {
  static propTypes = {
    getCustomer: PropTypes.func.isRequired,
    profile: PropTypes.shape({
      emailAddress: PropTypes.string,
      firstName: PropTypes.string,
      homePhoneNumber: PropTypes.string,
      lastName: PropTypes.string,
      mobilePhoneNumber: PropTypes.string,
      otherPhoneNumber: PropTypes.string,
      postalAddress: PropTypes.object,
      workPhoneNumber: PropTypes.string,
      interpreterId: PropTypes.string,
    }).isRequired,
  };

  componentDidMount() {
    this.props.getCustomer();
  }

  onClickAvatar = () => {
    if (this.avatarElement && this.avatarElement.onHiddenMenu) {
      this.avatarElement.onHiddenMenu();
    }
  }

  onClickLogout = () => {
    this.props.logout();
  }

  render() {
    let avatarCircleButton = null;
    if (this.props.profile.image && this.props.profile.image.Image) {
      avatarCircleButton = (
        <React.Fragment>
          <div className="tv-avatar">
            <img
              src={`data:image/${this.props.profile.image.ImageContentType};base64,${this.props.profile.image.Image}`}
              className="tv-avatar__img"
              alt="avatar"
            />
          </div>
          <span className={`tv-filter-list__user-name${isIE ? ' ie-fix' : ''}`}>
            {this.props.profile.name}
          </span>
        </React.Fragment>

      );
    } else {
      avatarCircleButton = (
        <React.Fragment>
          <div className="tv-avatar">
            <img src="/images/default-avatar.jpg" className="tv-avatar__img" alt="avatar" />
          </div>
          <span className={`tv-filter-list__user-name${isIE ? ' ie-fix' : ''}`}>
            {this.props.profile.name}
          </span>
        </React.Fragment>

      );
    }

    return (
      <DropdownMenu ref={(el) => { this.avatarElement = el; }} button={avatarCircleButton}>
        <div className="tv-avatar-list__menu">
          <div
            className="tv-avatar-list__menu-item tv-avatar-list__menu-item--customer-name"
            tabIndex="-1"
            onKeyDown={() => {}}
          >
            {this.props.profile.name}
          </div>
          <div
            className="tv-avatar-list__menu-item tv-avatar-list__menu-item--customer-number"
            tabIndex="-1"
            onKeyDown={() => {}}
          >
          </div>
          <NavLink
            onClick={this.onClickLogout}
            to="/login"
            className="tv-avatar-list__menu-item tv-avatar-list__menu-item--logout"
          >
            <i className="icon-log-out tv-avatar-list__menu-item-icon" /> <Translate content="avatar.logout" />
          </NavLink>
        </div>
      </DropdownMenu>
    );
  }
}
